import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IContractTemplate {
  id?: number;
  hourlyBased?: boolean;
  allowance?: boolean;
  aip?: boolean;
  gf?: boolean;
  pf?: boolean;
  onsiteAllowance?: boolean;
  performanceBonus?: boolean;
  medical?: boolean;
  templateCode?: IHrCode;
  employmentType?: IHrCode;
  payrollOffice?: IHrCode | null;
  currency?: IHrCode | null;
  templateStatus?: IHrCode;
}

export const defaultValue: Readonly<IContractTemplate> = {
  hourlyBased: false,
  allowance: false,
  aip: false,
  gf: false,
  pf: false,
  onsiteAllowance: false,
  performanceBonus: false,
  medical: false,
};
