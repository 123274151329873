import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ApprovalLog from './approval-log';
import ApprovalLogDetail from './approval-log-detail';
import ApprovalLogUpdate from './approval-log-update';
import ApprovalLogDeleteDialog from './approval-log-delete-dialog';

const ApprovalLogRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApprovalLog />} />
    <Route path="new" element={<ApprovalLogUpdate />} />
    <Route path=":id">
      <Route index element={<ApprovalLogDetail />} />
      <Route path="edit" element={<ApprovalLogUpdate />} />
      <Route path="delete" element={<ApprovalLogDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApprovalLogRoutes;
