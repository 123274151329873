import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities } from './emp-experience.reducer';

export const EmpExperienceHome = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const empExperienceList = useAppSelector(state => state.empExperience.entities);
  const loading = useAppSelector(state => state.empExperience.loading);
  const totalItems = useAppSelector(state => state.empExperience.totalItems);

  useEffect(() => {
    dispatch(getEntities({
      page: paginationState.activePage - 1,
      size: paginationState.itemsPerPage,
      sort: `toDate,desc`,
    }));
  }, []);

  // const getSortIconByFieldName = (fieldName: string) => {
  //   const sortFieldName = paginationState.sort;
  //   const order = paginationState.order;
  //   if (sortFieldName !== fieldName) {
  //     return faSort;
  //   } else {
  //     return order === ASC ? faSortUp : faSortDown;
  //   }
  // };

  return (
    <div>
      <h5 id="emp-experience-heading" data-cy="EmpExperienceHeading" className="text-center fw-bold text-primary p-1">
        <Translate contentKey="hrmsApp.empExperience.home.title">Emp Experiences</Translate>
        <div className="d-flex justify-content-end">
          <Link to="/emp-experience/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />&nbsp;
            <Translate contentKey="hrmsApp.empExperience.home.createLabel">Create new Emp Experience</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {empExperienceList && empExperienceList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th className="hand">
                  <Translate contentKey="hrmsApp.empExperience.organization">Organization</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empExperience.fromDate">From Date</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empExperience.toDate">To Date</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empExperience.lastRole">Last Role</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empExperience.responsibility">Responsibility</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empExperience.achievement">Achievement</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empExperience.stillWorking">Still Working</Translate>{' '}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {empExperienceList.map((empExperience, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{empExperience.organization}</td>
                  <td>
                    {empExperience.fromDate ? (
                      <TextFormat type="date" value={empExperience.fromDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {empExperience.toDate ? <TextFormat type="date" value={empExperience.toDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>{empExperience.lastRole}</td>
                  <td>{empExperience.responsibility}</td>
                  <td>{empExperience.achievement}</td>
                  <td>{empExperience.stillWorking ? 'true' : 'false'}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/emp-experience/${empExperience.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/emp-experience/${empExperience.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.empExperience.home.notFound">No Emp Experiences found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default EmpExperienceHome;
