import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EmpIce from './emp-ice';
import EmpIceDeleteDialog from './emp-ice-delete-dialog';
import EmpIceDetail from './emp-ice-detail';
import EmpIceUpdate from './emp-ice-update';

const EmpIceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EmpIce />} />
    <Route path="new" element={<EmpIceUpdate />} />
    <Route path=":id">
      <Route index element={<EmpIceDetail />} />
      <Route path="edit" element={<EmpIceUpdate />} />
      <Route path="delete" element={<EmpIceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmpIceRoutes;
