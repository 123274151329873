import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities } from './emp-reference.reducer';

export const EmpReferenceHome = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const empReferenceList = useAppSelector(state => state.empReference.entities);
  const loading = useAppSelector(state => state.empReference.loading);
  const totalItems = useAppSelector(state => state.empReference.totalItems);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  return (
    <div>
      <h5 id="emp-reference-heading" data-cy="EmpReferenceHeading" className="text-center fw-bold text-primary p-1">
        <Translate contentKey="hrmsApp.empReference.home.title">Emp References</Translate>
        <div className="d-flex justify-content-end">
          {/* <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="hrmsApp.empReference.home.refreshListLabel">Refresh</Translate>
          </Button> */}
          <Link to="/emp-reference/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />&nbsp;
            <Translate contentKey="hrmsApp.empReference.home.createLabel">Create new Emp Reference</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {empReferenceList && empReferenceList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.empReference.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th> */}
                <th className="hand">
                  <Translate contentKey="hrmsApp.empReference.fullName">Full Name</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empReference.organization">Organization</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empReference.designation">Designation</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empReference.linkage">Linkage</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empReference.email">Email</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empReference.contactNo">Contact No</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empReference.locatopn">Locatopn</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empReference.cnic">Cnic</Translate>{' '}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {empReferenceList.map((empReference, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  {/* <td>
                    <Button tag={Link} to={`/emp-reference/${empReference.id}`} color="link" size="sm">
                      {empReference.id}
                    </Button>
                  </td> */}
                  <td>{empReference.fullName}</td>
                  <td>{empReference.organization}</td>
                  <td>{empReference.designation}</td>
                  <td>{empReference.linkage}</td>
                  <td>{empReference.email}</td>
                  <td>{empReference.contactNo}</td>
                  <td>{empReference.locatopn}</td>
                  <td>{empReference.cnic}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/emp-reference/${empReference.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/emp-reference/${empReference.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.empReference.home.notFound">No Emp References found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default EmpReferenceHome;
