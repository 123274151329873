import { IBusinessClient } from 'app/shared/model/business-client.model';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IClientContact {
  id?: number;
  firstName?: string;
  lastName?: string | null;
  designation?: string;
  email?: string;
  contactNos?: string;
  remarks?: string | null;
  businessClient?: IBusinessClient;
  status?: IHrCode;
}

export const defaultValue: Readonly<IClientContact> = {};
