import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import HrCode from './hr-code';
import AllowanceItem from './allowance-item';
import AllowanceItemLog from './allowance-item-log';
import AllowancePlan from './allowance-plan';
import Employee from './employee';
import EmpProfile from './emp-profile';
import EmpDependent from './emp-dependent';
import AuthorityDelegation from './authority-delegation';
import ApprovalLimit from './approval-limit';
import ApprovalWorkflow from './approval-workflow';
import PendingApproval from './pending-approval';
import ApprovalLog from './approval-log';
import LeavesPlan from './leaves-plan';
import MedicalPlan from './medical-plan';
import ContractTemplate from './contract-template';
import Contract from './contract';
import FcmToken from './fcm-token';
import FcmTemplate from './fcm-template';
import FcmNotification from './fcm-notification';
import EmpIce from './emp-ice';
import EmpQualification from './emp-qualification';
import EmpCertification from './emp-certification';
import EmpExperience from './emp-experience';
import EmpReference from './emp-reference';
import LeavesRecord from './leaves-record';
import MedicalRecord from './medical-record';
import CountryWorkday from './country-workday';
import CountryHoliday from './country-holiday';
import BusinessClient from './business-client';
import ClientContact from './client-contact';
import BusinessProject from './business-project';
import ProjectTask from './project-task';
import TaskAssignment from './task-assignment';
import TimeSheet from './time-sheet';
import InternalTask from './internal-task';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="hr-code/*" element={<HrCode />} />
        <Route path="allowance-item/*" element={<AllowanceItem />} />
        <Route path="allowance-item-log/*" element={<AllowanceItemLog />} />
        <Route path="allowance-plan/*" element={<AllowancePlan />} />
        <Route path="employee/*" element={<Employee />} />
        <Route path="emp-profile/*" element={<EmpProfile />} />
        <Route path="emp-dependent/*" element={<EmpDependent />} />
        <Route path="authority-delegation/*" element={<AuthorityDelegation />} />
        <Route path="approval-limit/*" element={<ApprovalLimit />} />
        <Route path="approval-workflow/*" element={<ApprovalWorkflow />} />
        <Route path="pending-approval/*" element={<PendingApproval />} />
        <Route path="approval-log/*" element={<ApprovalLog />} />
        <Route path="leaves-plan/*" element={<LeavesPlan />} />
        <Route path="medical-plan/*" element={<MedicalPlan />} />
        <Route path="contract-template/*" element={<ContractTemplate />} />
        <Route path="contract/*" element={<Contract />} />
        <Route path="fcm-token/*" element={<FcmToken />} />
        <Route path="fcm-template/*" element={<FcmTemplate />} />
        <Route path="fcm-notification/*" element={<FcmNotification />} />
        <Route path="emp-ice/*" element={<EmpIce />} />
        <Route path="emp-qualification/*" element={<EmpQualification />} />
        <Route path="emp-certification/*" element={<EmpCertification />} />
        <Route path="emp-experience/*" element={<EmpExperience />} />
        <Route path="emp-reference/*" element={<EmpReference />} />
        <Route path="leaves-record/*" element={<LeavesRecord />} />
        <Route path="medical-record/*" element={<MedicalRecord />} />
        <Route path="country-workday/*" element={<CountryWorkday />} />
        <Route path="country-holiday/*" element={<CountryHoliday />} />
        <Route path="business-client/*" element={<BusinessClient />} />
        <Route path="client-contact/*" element={<ClientContact />} />
        <Route path="business-project/*" element={<BusinessProject />} />
        <Route path="project-task/*" element={<ProjectTask />} />
        <Route path="task-assignment/*" element={<TaskAssignment />} />
        <Route path="time-sheet/*" element={<TimeSheet />} />
        <Route path="internal-task/*" element={<InternalTask />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
