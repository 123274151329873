import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EmpQualification from './emp-qualification';
import EmpQualificationDeleteDialog from './emp-qualification-delete-dialog';
import EmpQualificationDetail from './emp-qualification-detail';
import EmpQualificationUpdate from './emp-qualification-update';

const EmpQualificationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EmpQualification />} />
    <Route path="new" element={<EmpQualificationUpdate />} />
    <Route path=":id">
      <Route index element={<EmpQualificationDetail />} />
      <Route path="edit" element={<EmpQualificationUpdate />} />
      <Route path="delete" element={<EmpQualificationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmpQualificationRoutes;
