import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './approval-log.reducer';

export const ApprovalLogDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const approvalLogEntity = useAppSelector(state => state.approvalLog.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="approvalLogDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.approvalLog.detail.title">ApprovalLog</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{approvalLogEntity.id}</dd>
          <dt>
            <span id="dated">
              <Translate contentKey="hrmsApp.approvalLog.dated">Dated</Translate>
            </span>
          </dt>
          <dd>
            {approvalLogEntity.dated ? <TextFormat value={approvalLogEntity.dated} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="entityId">
              <Translate contentKey="hrmsApp.approvalLog.entityId">Entity Id</Translate>
            </span>
          </dt>
          <dd>{approvalLogEntity.entityId}</dd>
          <dt>
            <span id="roleName">
              <Translate contentKey="hrmsApp.approvalLog.roleName">Role</Translate>
            </span>
          </dt>
          <dd>{approvalLogEntity.roleName}</dd>
          <dt>
            <span id="remarks">
              <Translate contentKey="hrmsApp.approvalLog.remarks">Remarks</Translate>
            </span>
          </dt>
          <dd>{approvalLogEntity.remarks}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalLog.workflow">Workflow</Translate>
          </dt>
          <dd>{approvalLogEntity.workflow ? approvalLogEntity.workflow.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalLog.entityStatus">Entity Status</Translate>
          </dt>
          <dd>{approvalLogEntity.entityStatus ? approvalLogEntity.entityStatus.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalLog.initiator">Initiator</Translate>
          </dt>
          <dd>{approvalLogEntity.initiator ? approvalLogEntity.initiator.qlid : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalLog.approver">Approver</Translate>
          </dt>
          <dd>{approvalLogEntity.approver ? approvalLogEntity.approver.qlid : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalLog.delegator">Delegator</Translate>
          </dt>
          <dd>{approvalLogEntity.delegator ? approvalLogEntity.delegator.qlid : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalLog.action">Action</Translate>
          </dt>
          <dd>{approvalLogEntity.action ? approvalLogEntity.action.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/approval-log" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/approval-log/${approvalLogEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ApprovalLogDetail;
