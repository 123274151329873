import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './employee.reducer';

export const EmployeeDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const employeeEntity = useAppSelector(state => state.employee.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="employeeDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.employee.detail.title">Employee</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{employeeEntity.id}</dd>
          <dt>
            <Translate contentKey="hrmsApp.employee.employeeType">Employee Type</Translate>
          </dt>
          <dd>{employeeEntity.employeeType ? employeeEntity.employeeType.codeName : ''}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="hrmsApp.employee.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{employeeEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="hrmsApp.employee.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{employeeEntity.lastName}</dd>
          <dt>
            <span id="qlid">
              <Translate contentKey="hrmsApp.employee.qlid">Qlid</Translate>
            </span>
          </dt>
          <dd>{employeeEntity.qlid}</dd>
          <dt>
            <span id="dob">
              <Translate contentKey="hrmsApp.employee.dob">DOB</Translate>
            </span>
          </dt>
          <dd>{employeeEntity.dob ? <TextFormat value={employeeEntity.dob} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="joiningDate">
              <Translate contentKey="hrmsApp.employee.joiningDate">Joining Date</Translate>
            </span>
          </dt>
          <dd>
            {employeeEntity.joiningDate ? (
              <TextFormat value={employeeEntity.joiningDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="hrmsApp.employee.user">User</Translate>
          </dt>
          <dd>{employeeEntity.user ? employeeEntity.user.login : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.employee.reportingLine">Reporting Line</Translate>
          </dt>
          <dd>{employeeEntity.reportingLine ? employeeEntity.reportingLine.qlid : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.employee.employeeStatus">Employee Status</Translate>
          </dt>
          <dd>{employeeEntity.employeeStatus ? employeeEntity.employeeStatus.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/employee" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/employee/${employeeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmployeeDetail;
