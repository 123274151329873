import { useAppSelector } from 'app/config/store';
import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

interface ProfileModalProps {
  show: boolean;
  onHide: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ show, onHide }) => {
  const account = useAppSelector(state => state.authentication.account);
  return (
    <Modal isOpen={show} toggle={onHide}>
      <ModalHeader toggle={onHide}>
        <div className="text-primary justify-text-center fw-bold text-primary">Profile</div>
      </ModalHeader>
      <ModalBody>
      <Row className="justify-content-center">
            <Col md="12">
            <Table responsive>
              <tbody>
              <tr><td className="text-primary">Login:</td><td>{account.login}</td></tr>
              <tr><td className="text-primary">First Name:</td><td>{account.firstName}</td></tr>
              <tr><td className="text-primary">Last Name:</td><td>{account.lastName}</td></tr>
              <tr><td className="text-primary">Email:</td><td>{account.email}</td></tr>
              <tr><td className="text-primary">Created On:</td><td>{account.createdDate}</td></tr>
              <tr><td className="text-primary">Assigned Roles:</td>
                <td> 
                  <ul id="myList">
                  {account.authorities.map((authority, index) => (
                    <div key={index}>
                      <li>{authority}</li>
                    </div>
                  ))}
                </ul>
              </td></tr>
              </tbody>
            </Table>
            </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHide}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfileModal;
