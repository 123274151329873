import dayjs from 'dayjs';
import { IBusinessProject } from 'app/shared/model/business-project.model';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IProjectTask {
  id?: number;
  taskName?: string;
  taskDescription?: string | null;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  billable?: boolean;
  businessProject?: IBusinessProject;
  parentTask?: IProjectTask | null;
  dependsOn?: IProjectTask | null;
  status?: IHrCode;
}

export const defaultValue: Readonly<IProjectTask> = {
  billable: false,
};
