import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MedicalRecord from './medical-record';
import MedicalRecordDetail from './medical-record-detail';
import MedicalRecordUpdate from './medical-record-update';
import MedicalRecordDeleteDialog from './medical-record-delete-dialog';

const MedicalRecordRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MedicalRecord />} />
    <Route path="new" element={<MedicalRecordUpdate />} />
    <Route path=":id">
      <Route index element={<MedicalRecordDetail />} />
      <Route path="edit" element={<MedicalRecordUpdate />} />
      <Route path="delete" element={<MedicalRecordDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MedicalRecordRoutes;
