import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FcmNotification from './fcm-notification';
import FcmNotificationDetail from './fcm-notification-detail';
import FcmNotificationUpdate from './fcm-notification-update';
import FcmNotificationDeleteDialog from './fcm-notification-delete-dialog';

const FcmNotificationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FcmNotification />} />
    <Route path="new" element={<FcmNotificationUpdate />} />
    <Route path=":id">
      <Route index element={<FcmNotificationDetail />} />
      <Route path="edit" element={<FcmNotificationUpdate />} />
      <Route path="delete" element={<FcmNotificationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FcmNotificationRoutes;
