import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { createEntity, getEntity, reset, updateEntity } from './authority-delegation.reducer';

export const AuthorityDelegationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const employees = useAppSelector(state => state.employee.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const authorityDelegationEntity = useAppSelector(state => state.authorityDelegation.entity);
  const loading = useAppSelector(state => state.authorityDelegation.loading);
  const updating = useAppSelector(state => state.authorityDelegation.updating);
  const updateSuccess = useAppSelector(state => state.authorityDelegation.updateSuccess);

  const handleClose = () => {
    navigate('/authority-delegation' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    // dispatch(getEmployees({}));
    // dispatch(getHrCodes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...authorityDelegationEntity,
      ...values,
      delegator: employees.find(it => it.id.toString() === values.delegator.toString()),
      delegatee: employees.find(it => it.id.toString() === values.delegatee.toString()),
      status: hrCodes.find(it => it.id.toString() === values.status.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...authorityDelegationEntity,
          delegator: authorityDelegationEntity?.delegator?.id,
          delegatee: authorityDelegationEntity?.delegatee?.id,
          status: authorityDelegationEntity?.status?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.authorityDelegation.home.createOrEditLabel"
            data-cy="AuthorityDelegationCreateUpdateHeading"
            className="text-center fw-bold text-primary"
          >
            <Translate contentKey="hrmsApp.authorityDelegation.home.createOrEditLabel">Create or edit a AuthorityDelegation</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="authority-delegation-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.authorityDelegation.startedOn')}
                id="authority-delegation-startedOn"
                name="startedOn"
                data-cy="startedOn"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.authorityDelegation.endedOn')}
                id="authority-delegation-endedOn"
                name="endedOn"
                data-cy="endedOn"
                type="date"
              />
              <ValidatedField
                id="authority-delegation-delegator"
                name="delegator"
                data-cy="delegator"
                label={translate('hrmsApp.authorityDelegation.delegator')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {employees
                  ? employees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.qlid} - {otherEntity.firstName} {otherEntity.lastName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="authority-delegation-delegatee"
                name="delegatee"
                data-cy="delegatee"
                label={translate('hrmsApp.authorityDelegation.delegatee')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {employees
                  ? employees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.qlid} - {otherEntity.firstName} {otherEntity.lastName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="authority-delegation-status"
                name="status"
                data-cy="status"
                label={translate('hrmsApp.authorityDelegation.status')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'EMS' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/authority-delegation" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AuthorityDelegationUpdate;
