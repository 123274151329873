import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

import { createEntity, getEntity, reset, updateEntity } from './approval-log.reducer';

export const ApprovalLogUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const approvalLogEntity = useAppSelector(state => state.approvalLog.entity);
  const loading = useAppSelector(state => state.approvalLog.loading);
  const updating = useAppSelector(state => state.approvalLog.updating);
  const updateSuccess = useAppSelector(state => state.approvalLog.updateSuccess);

  const handleClose = () => {
    navigate('/approval-log' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    // dispatch(getHrCodes({}));
    // dispatch(getEmployees({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    values.dated = convertDateTimeToServer(values.dated);
    if (values.entityId !== undefined && typeof values.entityId !== 'number') {
      values.entityId = Number(values.entityId);
    }

    const entity = {
      ...approvalLogEntity,
      ...values,
      workflow: hrCodes.find(it => it.id.toString() === values.workflow.toString()),
      entityStatus: hrCodes.find(it => it.id.toString() === values.entityStatus.toString()),
      action: hrCodes.find(it => it.id.toString() === values.action.toString()),
      initiator: employees.find(it => it.id.toString() === values.initiator.toString()),
      approver: employees.find(it => it.id.toString() === values.approver.toString()),
      delegator: employees.find(it => it.id.toString() === values.delegator.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          dated: displayDefaultDateTime(),
        }
      : {
          ...approvalLogEntity,
          dated: convertDateTimeFromServer(approvalLogEntity.dated),
          workflow: approvalLogEntity?.workflow?.id,
          entityStatus: approvalLogEntity?.entityStatus?.id,
          initiator: approvalLogEntity?.initiator?.id,
          approver: approvalLogEntity?.approver?.id,
          delegator: approvalLogEntity?.delegator?.id,
          action: approvalLogEntity?.action?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.approvalLog.home.createOrEditLabel"
            data-cy="ApprovalLogCreateUpdateHeading"
            className="text-center fw-bold text-primary"
          >
            <Translate contentKey="hrmsApp.approvalLog.home.createOrEditLabel">Create or edit a ApprovalLog</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="approval-log-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.approvalLog.dated')}
                id="approval-log-dated"
                name="dated"
                data-cy="dated"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.approvalLog.entityId')}
                id="approval-log-entityId"
                name="entityId"
                data-cy="entityId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.approvalLog.remarks')}
                id="approval-log-remarks"
                name="remarks"
                data-cy="remarks"
                type="text"
              />
              <ValidatedField
                id="approval-log-workflow"
                name="workflow"
                data-cy="workflow"
                label={translate('hrmsApp.approvalLog.workflow')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'WKF' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="approval-log-entityStatus"
                name="entityStatus"
                data-cy="entityStatus"
                label={translate('hrmsApp.approvalLog.entityStatus')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'WKS' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="approval-log-initiator"
                name="initiator"
                data-cy="initiator"
                label={translate('hrmsApp.approvalLog.initiator')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {employees
                  ? employees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.qlid} : {' (' + otherEntity.firstName + ' ' + otherEntity.lastName + ')'}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="approval-log-approver"
                name="approver"
                data-cy="approver"
                label={translate('hrmsApp.approvalLog.approver')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {employees
                  ? employees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.qlid} : {' (' + otherEntity.firstName + ' ' + otherEntity.lastName + ')'}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="approval-log-delegator"
                name="delegator"
                data-cy="delegator"
                label={translate('hrmsApp.approvalLog.delegator')}
                type="select"
              >
                <option value="" key="0" />
                {employees
                  ? employees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.qlid} : {' (' + otherEntity.firstName + ' ' + otherEntity.lastName + ')'}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="approval-log-action"
                name="action"
                data-cy="action"
                label={translate('hrmsApp.approvalLog.action')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'WKA' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/approval-log" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ApprovalLogUpdate;
