import dayjs from 'dayjs';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface ILeavesPlan {
  id?: number;
  qty?: number;
  cumulativeLimit?: number;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs | null;
  planName?: IHrCode;
  itemName?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<ILeavesPlan> = {};
