import dayjs from 'dayjs';
import { IFcmTemplate } from 'app/shared/model/fcm-template.model';
import { IUser } from 'app/shared/model/user.model';
import { fcmStatus } from 'app/shared/model/enumerations/fcm-status.model';

export interface IFcmNotification {
  id?: number;
  body?: string;
  fcmStatus?: keyof typeof fcmStatus;
  createdAt?: dayjs.Dayjs;
  lastUpdated?: dayjs.Dayjs | null;
  fcmTemplate?: IFcmTemplate | null;
  user?: IUser;
}

export const defaultValue: Readonly<IFcmNotification> = {};
