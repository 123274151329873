import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities } from './hr-code.reducer';

export const HrCode = () => {
  const dispatch = useAppDispatch();
  const [searchFields, setSearchFields] = useState({
    id: '',
    codeType: '',
    codeName: '',
    codeDescription: '',
    codeStatus: '',
    codeSeq: '',
  });

  const [selectedList, setSelectedList] = useState([]);

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const hrCodeList = useAppSelector(state => state.hrCode.entities);
  const loading = useAppSelector(state => state.hrCode.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  useEffect(() => {
    setSelectedList(hrCodeList);
  }, [hrCodeList]);

  /* eslint no-console: off */
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setSearchFields(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      console.log(`Search for ${field} with value: ${searchFields[field]}`);
      if (field==='id') {
        setSelectedList(hrCodeList.filter(it => it[field] === parseInt(searchFields[field],10)));
      } else {
        setSelectedList(hrCodeList.filter(it=> it[field]?.toLowerCase().includes(searchFields[field].toLowerCase())));
      }
    }
  };

  const filterList = () => {
    setSelectedList(hrCodeList.filter(it=> {
      return Object.keys(searchFields).every(field => {
        const fieldValue = searchFields[field].toLowerCase();
        return !fieldValue || it[field]?.toLowerCase().includes(fieldValue);
      });
    }));
  };
  
  return (
    <div>
      <h5 id="hr-code-heading" data-cy="HrCodeHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.hrCode.home.title">Hr Codes</Translate>
        <div className="d-flex justify-content-end">
          <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="hrmsApp.hrCode.home.refreshListLabel">Refresh</Translate>
          </Button>
          <Link to="/hr-code/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="hrmsApp.hrCode.home.createLabel">Create new Hr Code</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {selectedList && selectedList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <input type="text" className="border border-gray w-100" onChange={(e)=>handleInputChange(e,`id`)} onKeyDown={(e)=>handleKeyDown(e,'id')} />
                </th>
                <th>
                  <input type="text" className="border border-gray w-100" onChange={(e)=>handleInputChange(e,`codeType`)} onKeyDown={(e)=>handleKeyDown(e,'codeType')} />
                </th>
                <th>
                  <input type="text" className="border border-gray w-100" onChange={(e)=>handleInputChange(e,`codeName`)} onKeyDown={(e)=>handleKeyDown(e,'codeName')} />
                </th>
                <th>
                  <input type="text" className="border border-gray w-100" onChange={(e)=>handleInputChange(e,`codeDescription`)} onKeyDown={(e)=>handleKeyDown(e,'codeDescription')} />
                </th>
                <th>
                  <input type="text" className="border border-gray w-100" onChange={(e)=>handleInputChange(e,`codeStatus`)} onKeyDown={(e)=>handleKeyDown(e,'codeStatus')} />
                </th>
                <th>
                  <input type="text" className="border border-gray w-100" disabled />
                </th>
                <th>
                  <input type="text" disabled />
                </th>
                <th>
                <Button className="btn btn-sm border border-gray" color="info" onClick={filterList}  disabled={loading}>Search
                  {/* <FontAwesomeIcon icon="search" spin={loading} />{' '} */}
                </Button>
                </th>
                {/* <th /> */}
              </tr>
              <tr>
                <th className="hand">
                  <Translate contentKey="hrmsApp.hrCode.id">ID</Translate> 
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')}  onClick={sort('id')}/>
                </th>
                <th className="hand" onClick={sort('codeType')}>
                  <Translate contentKey="hrmsApp.hrCode.codeType">Code Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('codeType')} />
                </th>
                <th className="hand" onClick={sort('codeName')}>
                  <Translate contentKey="hrmsApp.hrCode.codeName">Code Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('codeName')} />
                </th>
                <th className="hand" onClick={sort('codeDescription')}>
                  <Translate contentKey="hrmsApp.hrCode.codeDescription">Code Description</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('codeDescription')} />
                </th>
                <th className="hand" onClick={sort('codeStatus')}>
                  <Translate contentKey="hrmsApp.hrCode.codeStatus">Code Status</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('codeStatus')} />
                </th>
                <th className="hand" onClick={sort('codeSeq')}>
                  <Translate contentKey="hrmsApp.hrCode.codeSeq">Code Seq</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('codeSeq')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.hrCode.parent">Parent</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedList.map((hrCode, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/hr-code/${hrCode.id}`} color="link" size="sm">
                      {hrCode.id}
                    </Button>
                  </td>
                  <td>{hrCode.codeType}</td>
                  <td>{hrCode.codeName}</td>
                  <td>{hrCode.codeDescription}</td>
                  <td>
                    {hrCode.codeStatus}
                  </td>
                  <td>{hrCode.codeSeq}</td>
                  <td>{hrCode.parent ? <Link to={`/hr-code/${hrCode.parent.id}`}>{hrCode.parent.codeType} - {hrCode.parent.codeName}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      {/* <Button tag={Link} to={`/hr-code/${hrCode.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button> */}
                      <Button tag={Link} to={`/hr-code/${hrCode.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        onClick={() => (window.location.href = `/hr-code/${hrCode.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.hrCode.home.notFound">No Hr Codes found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default HrCode;
