import dayjs from 'dayjs';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IAllowancePlan {
  id?: number;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs | null;
  allowancePlan?: IHrCode;
  allowanceItem?: IHrCode;
  allowanceStatus?: IHrCode;
}

export const defaultValue: Readonly<IAllowancePlan> = {};
