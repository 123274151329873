import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BusinessProject from './business-project';
import BusinessProjectDetail from './business-project-detail';
import BusinessProjectUpdate from './business-project-update';
import BusinessProjectDeleteDialog from './business-project-delete-dialog';

const BusinessProjectRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BusinessProject />} />
    <Route path="new" element={<BusinessProjectUpdate />} />
    <Route path=":id">
      <Route index element={<BusinessProjectDetail />} />
      <Route path="edit" element={<BusinessProjectUpdate />} />
      <Route path="delete" element={<BusinessProjectDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BusinessProjectRoutes;
