import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProjectTask from './project-task';
import ProjectTaskDetail from './project-task-detail';
import ProjectTaskUpdate from './project-task-update';
import ProjectTaskDeleteDialog from './project-task-delete-dialog';

const ProjectTaskRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProjectTask />} />
    <Route path="new" element={<ProjectTaskUpdate />} />
    <Route path=":id">
      <Route index element={<ProjectTaskDetail />} />
      <Route path="edit" element={<ProjectTaskUpdate />} />
      <Route path="delete" element={<ProjectTaskDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProjectTaskRoutes;
