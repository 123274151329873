import dayjs from 'dayjs';
import { IContract } from 'app/shared/model/contract.model';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IMedicalRecord {
  id?: number;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs | null;
  expense?: number;
  attachmentPath?: string | null;
  contract?: IContract;
  medicalItem?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<IMedicalRecord> = {};
