import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

// import { getEntities as getEmployees } from 'app/entities/employee/employee.reducer';
import { relationInfo } from 'app/shared/model/enumerations/relation-info.model';
import { createEntity, getEntity, reset, updateEntity } from './emp-ice.reducer';

export const EmpIceUpdate = () => {
  const account = useAppSelector(state => state.authentication.account);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const employees = useAppSelector(state => state.employee.entities);
  const empIceEntity = useAppSelector(state => state.empIce.entity);
  const loading = useAppSelector(state => state.empIce.loading);
  const updating = useAppSelector(state => state.empIce.updating);
  const updateSuccess = useAppSelector(state => state.empIce.updateSuccess);
  const relationInfoValues = Object.keys(relationInfo);

  const handleClose = () => {
    navigate('/emp-ice');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...empIceEntity,
      ...values,
      // employee: account?.employeeRef?.toString(), //employees.find(it => it.id.toString() === values.employee.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          relationInfo: 'Spouse',
          ...empIceEntity,
          employee: empIceEntity?.employee?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 id="hrmsApp.empIce.home.createOrEditLabel" data-cy="EmpIceCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.empIce.home.createOrEditLabel">Create or edit a EmpIce</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('hrmsApp.empIce.fullName')}
                id="emp-ice-fullName"
                name="fullName"
                data-cy="fullName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empIce.relationInfo')}
                id="emp-ice-relationInfo"
                name="relationInfo"
                data-cy="relationInfo"
                type="select"
              >
                {relationInfoValues.map(relInfo => (
                  <option value={relInfo} key={relInfo}>
                    {translate('hrmsApp.relationInfo.' + relInfo)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.empIce.contactNo')}
                id="emp-ice-contactNo"
                name="contactNo"
                data-cy="contactNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empIce.curAddress')}
                id="emp-ice-curAddress"
                name="curAddress"
                data-cy="curAddress"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/emp-ice" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EmpIceUpdate;
