import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';
import { CertOrTrain } from 'app/shared/model/enumerations/cert-or-train.model';

export interface IEmpCertification {
  id?: number;
  trainingCert?: keyof typeof CertOrTrain;
  certName?: string;
  awardingOrg?: string;
  location?: string | null;
  regNo?: string | null;
  candidateId?: string | null;
  fromDate?: dayjs.Dayjs;
  toDate?: dayjs.Dayjs;
  validTill?: dayjs.Dayjs | null;
  employee?: IEmployee;
}

export const defaultValue: Readonly<IEmpCertification> = {};
