import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CountryHoliday from './country-holiday';
import CountryHolidayDetail from './country-holiday-detail';
import CountryHolidayUpdate from './country-holiday-update';
import CountryHolidayDeleteDialog from './country-holiday-delete-dialog';

const CountryHolidayRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CountryHoliday />} />
    <Route path="new" element={<CountryHolidayUpdate />} />
    <Route path=":id">
      <Route index element={<CountryHolidayDetail />} />
      <Route path="edit" element={<CountryHolidayUpdate />} />
      <Route path="delete" element={<CountryHolidayDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CountryHolidayRoutes;
