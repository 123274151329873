import 'app/config/dayjs';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Card } from 'reactstrap';

import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AppRoutes from 'app/routes';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import Header from 'app/shared/layout/header/header';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getSession } from 'app/shared/reducers/authentication';
import { setTextDirection } from './config/translation';
import { getToken } from "firebase/messaging";
import messaging from './fcm';
// import { createEntity } from './entities/hr-code/hr-code.reducer';
import { createEntity as createFCMEntity } from './entities/fcm-token/fcm-token.reducer';
import Sidebar from './shared/layout/menus/sidebar/sidebar';
/* eslint no-console: off */
const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();
  const [fcmtoken, setFcmtoken] = useState('');

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isCEO = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CEO]));
  const isCFO = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CFO]));
  const isHR = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.HR]));
  const isLead = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.LEAD]));
  const isApproverL1 = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.APPROVER_L1]));
  const isApproverL2 = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.APPROVER_L2]));
  const isApproverL3 = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.APPROVER_L3]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const fcmTokenEntity = useAppSelector(state => state.fcmToken.entity);
  // const authMatrix = useAppSelector(state => state.userManagement.authMatrix);
  const account = useAppSelector(state => state.authentication.account);

  useEffect(() => {
    setTextDirection(currentLocale);
  }, [currentLocale]);
  useEffect(() => {
    if (isAuthenticated) {
      const checkNotificationPermission = async () => {
        if (Notification.permission === 'granted') {
          getToken(messaging, { vapidKey: 'BEhW4-IpnPpxqoHyecCOn38r90pDiXP_sVkNUYOurRNNlQGG2ZHc-Ur2MdfgAGyG_Tb-G5x6UM9xLu9eTVYczPk' })
            .then((currentToken) => {
              if (currentToken) {
                setFcmtoken(currentToken);
                // Send the token to your server and update the UI if necessary
              } else {
                console.log('----> No FCM registration token available.');
              }
            })
            .catch((err) => {
              console.log('----> An error occurred while retrieving fcm token. ', err);
            });

          // Listen for incoming FCM messages
          // (messaging as any).onMessage((payload: any) => {
          //   console.log('Message received:', payload);
          //   // Handle incoming message here, update UI, trigger actions, etc.
          // });

        } else if (Notification.permission === 'denied') {
          console.log('----> Notification permission is denied. Please enable it in your browser settings.');
        } else {
          // Permission is not granted or denied, so request it
          console.log('----> FCM Permission Not granted so far');
          try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
              console.log('----> Notification permission granted.');
            } else {
              console.log('----> Notification permission denied.');
            }
          } catch (error) {
            console.log('----> Error requesting notification permission:', error);
          }
        }
      };
      checkNotificationPermission();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (fcmtoken) {
      console.log(`------> FCM Token : ${fcmtoken}`);
      const entity = {
        ...fcmTokenEntity,
        token: fcmtoken,
        createdAt: new Date().toISOString(),
      };
      dispatch(createFCMEntity(entity));
    }
  }, [fcmtoken]);

  // useEffect(() => {
  //   const unsubscribe = (messaging as any).onMessage((payload: any) => {
  //     // Handle incoming message here
  //     console.log('Received message in foreground:', payload);
  
  //     // Access notification data and handle it appropriately
  //     // Example: Display an alert with the message
  //     if (payload.notification) {
  //       const notificationTitle = payload.notification.title;
  //       const notificationBody = payload.notification.body;
  //       console.log("============ Received: "+notificationTitle+" --- "+notificationBody);
  //     }
  //   });
  
  //   return unsubscribe; // Don't forget to unsubscribe to avoid memory leaks
  // }, []);

  /* eslint no-console: off */
  const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard');
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setIsSidebarExpanded(!isSidebarExpanded);
    console.log('>>====> SideMenue Selected: ', menuItem);
  };


  const paddingTop = '40px';
  const paddingBottom = '0px'; // 40px
  return (
    <BrowserRouter basename={baseHref}>
      <div className="app-container d-flex flex-column" style={{ paddingTop, paddingBottom }}>
        <ToastContainer position="top-left" className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
          <Header
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            isCEO={isCEO}
            isCFO={isCFO}
            isHR={isHR}
            isLead={isLead}
            isApproverL1={isApproverL1}
            isApproverL2={isApproverL2}
            isApproverL3={isApproverL3}
            currentLocale={currentLocale}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled}
          />
        </ErrorBoundary>
        {/* <div className="container-fluid view-container flex-grow-1 p-0" id="app-view-container"> */}
        <div className="container-fluid view-container flex-grow-1 m-0 p-0" id="app-view-container">
          {account?.employeeRef != null && <Sidebar onMenuItemClick={handleMenuItemClick} />}
          {/* <Card className="row m-0 py-2"> */}
          {/* <div className={`main-content ${isSidebarExpanded ? 'sidebar-expanded' : 'sidebar-collapsed'}`}> */}
            <Card className={`flex-grow-1 p-2 pt-4`}>
              <ErrorBoundary>
                {/* {account?.employeeRef != null && <Sidebar onMenuItemClick={handleMenuItemClick} />} */}
                <AppRoutes />
              </ErrorBoundary>
            </Card>
          {/* </div> */}
        </div>
        {/* <Footer /> */}
      </div>
    </BrowserRouter>
  );
};

export default App;
