import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { getEntities as getBusinessClients } from 'app/entities/business-client/business-client.reducer';
import { getEntities as getEmployees } from 'app/entities/employee/employee.reducer';
import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntity, reset, updateEntity } from './business-project.reducer';

export const BusinessProjectUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const businessClients = useAppSelector(state => state.businessClient.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const businessProjectEntity = useAppSelector(state => state.businessProject.entity);
  const loading = useAppSelector(state => state.businessProject.loading);
  const updating = useAppSelector(state => state.businessProject.updating);
  const updateSuccess = useAppSelector(state => state.businessProject.updateSuccess);

  const handleClose = () => {
    navigate('/business-project');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getHrCodes({}));
    dispatch(getBusinessClients({}));
    dispatch(getEmployees({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...businessProjectEntity,
      ...values,
      projectType: isNew? hrCodes.find(e => e.codeType==='POT' && e.codeName==='W@R') : hrCodes.find(it => it.id.toString() === values.projectType?.toString()),
      mainClient: businessClients.find(it => it.id.toString() === values.mainClient?.toString()),
      partnerClient: businessClients.find(it => it.id.toString() === values.partnerClient?.toString()),
      industry: hrCodes.find(it => it.id.toString() === values.industry?.toString()),
      engagementType: hrCodes.find(it => it.id.toString() === values.engagementType?.toString()),
      engagementStatus: isNew? hrCodes.find(e => e.codeType==='WKS' && e.codeName==='Pending') : hrCodes.find(it => it.id.toString() === values.engagementStatus?.toString()),
      department: hrCodes.find(it => it.id.toString() === values.department?.toString()),
      location: hrCodes.find(it => it.id.toString() === values.location?.toString()),
      projectManager: employees.find(it => it.id.toString() === values.projectManager?.toString()),
      adhocManager: employees.find(it => it.id.toString() === values.adhocManager?.toString()),
    };
    if (new Date(entity.endDate) < new Date(entity.startDate)) {
      document.getElementById('business-project-message').innerHTML= '<span class="col-md-8 bg-danger">End Date cannot be before Start Date</span>';
      return;
    }

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...businessProjectEntity,
          projectType: businessProjectEntity?.projectType?.id,
          mainClient: businessProjectEntity?.mainClient?.id,
          partnerClient: businessProjectEntity?.partnerClient?.id,
          industry: businessProjectEntity?.industry?.id,
          engagementType: businessProjectEntity?.engagementType?.id,
          engagementStatus: businessProjectEntity?.engagementStatus?.id,
          department: businessProjectEntity?.department?.id,
          location: businessProjectEntity?.location?.id,
          projectManager: businessProjectEntity?.projectManager?.id,
          adhocManager: businessProjectEntity?.adhocManager?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 id="hrmsApp.businessProject.home.createOrEditLabel" data-cy="BusinessProjectCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.businessProject.home.createOrEditLabel">Create or edit a BusinessProject</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="business-project-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.businessProject.projectName')}
                id="business-project-projectName"
                name="projectName"
                data-cy="projectName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {!isNew ? (
                <ValidatedField
                  id="business-project-projectType"
                  name="projectType"
                  data-cy="projectType"
                  label={translate('hrmsApp.businessProject.projectType')}
                  type="select"
                  required
                >
                  <option value="" key="0" />
                  {hrCodes?
                      hrCodes.filter(outerEntity => outerEntity.codeType === 'POT' && outerEntity.codeName!=='Billable' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                    ))
                  : null}
                </ValidatedField>
              ) : null }
              <ValidatedField
                id="business-project-mainClient"
                name="mainClient"
                data-cy="mainClient"
                label={translate('hrmsApp.businessProject.mainClient')}
                type="select"
                required
              >
                <option value="" key="0" />
                {businessClients
                  ? businessClients.filter(e=>e.id>0).sort((a, b) => a.clientCode.localeCompare(b.clientCode)).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.clientCode} ({otherEntity.clientName}, {otherEntity.country.codeName})
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="business-project-partnerClient"
                name="partnerClient"
                data-cy="partnerClient"
                label={translate('hrmsApp.businessProject.partnerClient')}
                type="select"
              >
                <option value="" key="0" />
                {businessClients
                  ? businessClients.filter(e=>e.id>0).sort((a, b) => a.clientCode.localeCompare(b.clientCode)).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.clientCode} ({otherEntity.clientName}, {otherEntity.country.codeName})
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="business-project-industry"
                name="industry"
                data-cy="industry"
                label={translate('hrmsApp.businessProject.industry')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'IDY' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="business-project-engagementType"
                name="engagementType"
                data-cy="engagementType"
                label={translate('hrmsApp.businessProject.engagementType')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'EGT' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              {!isNew ? (
                <ValidatedField
                  id="business-project-engagementStatus"
                  name="engagementStatus"
                  data-cy="engagementStatus"
                  label={translate('hrmsApp.businessProject.engagementStatus')}
                  type="select"
                  required
                >
                  <option value="" key="0" />
                  {hrCodes?
                      hrCodes.filter(outerEntity => outerEntity.codeType === 'WKS' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                    ))
                  : null}
                </ValidatedField>
              ) : null}
              <ValidatedField
                id="business-project-department"
                name="department"
                data-cy="department"
                label={translate('hrmsApp.businessProject.department')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'DPT' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="business-project-location"
                name="location"
                data-cy="location"
                label={translate('hrmsApp.businessProject.location')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'LOC' && outerEntity.id%100>0).sort((a, b) => a.codeName.localeCompare(b.codeName)).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="business-project-projectManager"
                name="projectManager"
                data-cy="projectManager"
                label={translate('hrmsApp.businessProject.projectManager')}
                type="select"
                required
              >
                <option value="" key="0" />
                {employees
                  ? employees.filter(e=>e.id>0).sort((a, b) => a.firstName.localeCompare(b.firstName)).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.firstName} {otherEntity.lastName} 
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="business-project-adhocManager"
                name="adhocManager"
                data-cy="adhocManager"
                label={translate('hrmsApp.businessProject.adhocManager')}
                type="select"
              >
                <option value="" key="0" />
                {employees
                  ? employees.filter(e=>e.id>0).sort((a, b) => a.firstName.localeCompare(b.firstName)).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.firstName} {otherEntity.lastName} 
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.businessProject.startDate')}
                id="business-project-startDate"
                name="startDate"
                data-cy="startDate"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.businessProject.endDate')}
                id="business-project-endDate"
                name="endDate"
                data-cy="endDate"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  // minLength: { value: 3, message: translate('entity.validation.minlength', { min: 3 }) },
                  // custom: {
                  //   isValid: value => {
                  //     const selectedDate = new Date(value);
                  //     const twoYearsAgo = subYears(new Date(), 2);
                  //     return isBefore(twoYearsAgo, selectedDate);
                  //   },
                  //   message: translate('hrmsApp.businessProject.validation.startDateTooOld')
                  // }
                }}
            
              />
              <ValidatedField
                label={translate('hrmsApp.businessProject.remarks')}
                id="business-project-remarks"
                name="remarks"
                data-cy="remarks"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/business-project" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
      <Row id="business-project-message" className="justify-content-center text-white m-0 pt-1"></Row>
    </div>
  );
};

export default BusinessProjectUpdate;
