import dayjs from 'dayjs';
import { IHrCode } from 'app/shared/model/hr-code.model';
import { IEmployee } from 'app/shared/model/employee.model';

export interface IPendingApproval {
  id?: number;
  dated?: dayjs.Dayjs;
  entityId?: number;
  roleName?: string;
  remarks?: string | null;
  workflow?: IHrCode;
  entityStatus?: IHrCode;
  initiator?: IEmployee;
  delegator?: IEmployee | null;
  approver?: IEmployee | null;
  action?: IHrCode | null;
}

export const defaultValue: Readonly<IPendingApproval> = {};
