import { useAppSelector } from 'app/config/store';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
const HrCodeModal = ({ hrCodeEntity, isOpen, toggle }) => {
    const hrCodes = useAppSelector(state => state.hrCode.entities);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="text-primary justify-text-center fw-bold text-primary">Detail</div>
            </ModalHeader>
            <ModalBody>
                <Row className="justify-content-center">
                    <Col md="12">
                        <dl className="jh-entity-details bg-white">
                            {/* <dt><span id="id"><Translate contentKey="global.field.id">ID</Translate></span></dt>
                            <dd>{hrCodeEntity.id}</dd> */}
                            <dt><span id="codeType"><Translate contentKey="hrmsApp.hrCode.codeType">Code Type</Translate></span></dt>
                            <dd>{hrCodeEntity.codeType}</dd>
                            <dt><span id="codeName"><Translate contentKey="hrmsApp.hrCode.codeName">Code Name</Translate></span></dt>
                            <dd>{hrCodeEntity.codeName}</dd>
                            {hrCodeEntity.codeDescription? (
                                <>
                                    <dt><span id="codeDescription"><Translate contentKey="hrmsApp.hrCode.codeDescription">Code Description</Translate></span></dt>
                                    <dd>{hrCodeEntity.codeDescription}</dd>
                                </>
                                )
                                : ''
                            }
                            {hrCodeEntity.codeSeq? (
                                <>
                                    <dt><span id="codeSeq"> <Translate contentKey="hrmsApp.hrCode.codeSeq">Sequence</Translate> </span></dt>
                                    <dd>{hrCodeEntity.codeSeq.codeType+'-'+hrCodeEntity.codeSeq.codeName}</dd>
                                </>
                            ) : null}
                            {hrCodeEntity.parent? (
                                <>
                                    <dt><span id="parent"> <Translate contentKey="hrmsApp.hrCode.parent">Parent</Translate> </span></dt>
                                    <dd>{hrCodes.find(e=>e.id===hrCodeEntity.parent.id)?.codeName}</dd>
                                </>
                            ) : null}
                            <dt><span id="codeStatus"><Translate contentKey="hrmsApp.hrCode.codeStatus">Code Status</Translate></span></dt>
                            <dd>{hrCodeEntity.codeStatus}</dd>
                        </dl>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default HrCodeModal;