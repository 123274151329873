import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AllowanceItemLog from './allowance-item-log';
import AllowanceItemLogDetail from './allowance-item-log-detail';
import AllowanceItemLogUpdate from './allowance-item-log-update';
import AllowanceItemLogDeleteDialog from './allowance-item-log-delete-dialog';

const AllowanceItemLogRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AllowanceItemLog />} />
    <Route path="new" element={<AllowanceItemLogUpdate />} />
    <Route path=":id">
      <Route index element={<AllowanceItemLogDetail />} />
      <Route path="edit" element={<AllowanceItemLogUpdate />} />
      <Route path="delete" element={<AllowanceItemLogDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AllowanceItemLogRoutes;
