import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './emp-qualification.reducer';

export const EmpQualificationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const empQualificationEntity = useAppSelector(state => state.empQualification.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="empQualificationDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.empQualification.detail.title">EmpQualification</Translate>
        </h5>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{empQualificationEntity.id}</dd>
          <dt>
            <span id="degreeLevel">
              <Translate contentKey="hrmsApp.empQualification.degreeLevel">Degree Level</Translate>
            </span>
          </dt>
          <dd>{empQualificationEntity.degreeLevel}</dd>
          <dt>
            <span id="degreeName">
              <Translate contentKey="hrmsApp.empQualification.degreeName">Degree Name</Translate>
            </span>
          </dt>
          <dd>{empQualificationEntity.degreeName}</dd>
          <dt>
            <span id="majorSub">
              <Translate contentKey="hrmsApp.empQualification.majorSub">Major Sub</Translate>
            </span>
          </dt>
          <dd>{empQualificationEntity.majorSub}</dd>
          <dt>
            <span id="institute">
              <Translate contentKey="hrmsApp.empQualification.institute">Institute</Translate>
            </span>
          </dt>
          <dd>{empQualificationEntity.institute}</dd>
          <dt>
            <span id="passGrade">
              <Translate contentKey="hrmsApp.empQualification.passGrade">Pass Grade</Translate>
            </span>
          </dt>
          <dd>{empQualificationEntity.passGrade}</dd>
          <dt>
            <span id="passedOn">
              <Translate contentKey="hrmsApp.empQualification.passedOn">Passed On</Translate>
            </span>
          </dt>
          <dd>
            {empQualificationEntity.passedOn ? (
              <TextFormat value={empQualificationEntity.passedOn} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="anyAcheivement">
              <Translate contentKey="hrmsApp.empQualification.anyAcheivement">Any Acheivement</Translate>
            </span>
          </dt>
          <dd>{empQualificationEntity.anyAcheivement}</dd>
          <dt>
            <span id="isLast">
              <Translate contentKey="hrmsApp.empQualification.isLast">Is Last</Translate>
            </span>
          </dt>
          <dd>{empQualificationEntity.isLast ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="hrmsApp.empQualification.employee">Employee</Translate>
          </dt>
          <dd>{empQualificationEntity.employee ? empQualificationEntity.employee.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/emp-qualification" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/emp-qualification/${empQualificationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmpQualificationDetail;
