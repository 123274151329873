import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, ValidatedField, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import HrCodeModal from '../hr-code/hr-code-modal';
import { getEntities } from './allowance-plan.reducer';

export const AllowancePlan = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const allowancePlanList = useAppSelector(state => state.allowancePlan.entities);
  const loading = useAppSelector(state => state.allowancePlan.loading);

  const [selectedOption, setSelectedOption] = useState('');
  const [selectedList, setSelectedList] = useState([]);

  /* eslint no-console: off */
  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
    dispatch(getHrCodes({}));
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  useEffect(() => {
    setSelectedList(allowancePlanList);
  }, [allowancePlanList]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    setSelectedList(selectedOption? allowancePlanList.filter(it=>it.allowancePlan.id === parseInt(selectedOption, 10)) : allowancePlanList);
  }, [selectedOption]);

  // useEffect(() => {
  //   console.log('------- selected, allowancePlan, selectedList: ', selectedOption, allowancePlanList.length, selectedList.length);
  // }, [selectedList]);
  const [selectedHrCode, setSelectedHrCode] = useState(null);
  const [isHrCodeModalOpen, setHrCodeModalOpen] = useState(false);
  const toggleHrCodeModal = () => {
    setHrCodeModalOpen(!isHrCodeModalOpen);
  };

  const showHrCodeModal = (detail) => {
    setSelectedHrCode(detail);
    console.log('-----> val: ', detail.codeType, detail.codeName, detail.parent?.id);
    toggleHrCodeModal();
  };

  return (
    <div>
      <h5 id="allowance-plan-heading" data-cy="AllowancePlanHeading" className="text-center text-primary fw-bold m-0 p-0">
        <Translate contentKey="hrmsApp.allowancePlan.home.title">Allowance Plans</Translate>
        <div className="d-flex mt-3 justify-content-between align-items-start">
            <ValidatedField
                // id="allowance-plan-allowancePlan"
                name="allowancePlanSearch"
                type="select"
                value={selectedOption}
                onChange={handleSelectChange}
            >
              <option value="" key="0" />
              {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'ALP' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
            </ValidatedField>
            <div className="d-flex">
                <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
                  <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                  <Translate contentKey="hrmsApp.allowancePlan.home.refreshListLabel">Refresh</Translate>
                </Button>
                <Link to="/allowance-plan/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;
                  <Translate contentKey="hrmsApp.allowancePlan.home.createLabel">Create new Allowance Plan</Translate>
                </Link>
            </div>
        </div>
      </h5>
      <div className="table-responsive">
        {hrCodes && hrCodes.length>0 && selectedList && selectedList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.allowancePlan.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowancePlan.allowancePlan">Allowance Plan</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowancePlan.allowanceItem">Allowance Item</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('startDate')}>
                  <Translate contentKey="hrmsApp.allowancePlan.startDate">Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('startDate')} />
                </th>
                <th className="hand" onClick={sort('endDate')}>
                  <Translate contentKey="hrmsApp.allowancePlan.endDate">End Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('endDate')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowancePlan.allowanceStatus">Allowance Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedList.map((allowancePlan, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/allowance-plan/${allowancePlan.id}`} color="link" size="sm">
                      {allowancePlan.id}
                    </Button>
                  </td>
                  <td>
                    {allowancePlan.allowancePlan ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===allowancePlan.allowancePlan.id))}>{hrCodes.find(e=>e.id===allowancePlan.allowancePlan.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {allowancePlan.allowanceItem ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===allowancePlan.allowanceItem.id))}>{hrCodes.find(e=>e.id===allowancePlan.allowanceItem.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {allowancePlan.startDate ? (
                      <TextFormat type="date" value={allowancePlan.startDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {allowancePlan.endDate ? <TextFormat type="date" value={allowancePlan.endDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {allowancePlan.allowanceStatus ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===allowancePlan.allowanceStatus.id))}>{hrCodes.find(e=>e.id===allowancePlan.allowanceStatus.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/allowance-plan/${allowancePlan.id}/edit`} color="primary" size="sm" data-cy="entityEditButton" > <FontAwesomeIcon icon="pencil-alt" />{' '}
                      </Button>
                      <Button onClick={() => (window.location.href = `/allowance-plan/${allowancePlan.id}/delete`)} color="danger" size="sm" data-cy="entityDeleteButton" > <FontAwesomeIcon icon="trash" />{' '}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.allowancePlan.home.notFound">No Allowance Plans found</Translate>
            </div>
          )
        )}
      </div>
      {selectedHrCode && (
          <HrCodeModal hrCodeEntity={selectedHrCode} isOpen={isHrCodeModalOpen} toggle={toggleHrCodeModal} />
        )}
    </div>
  );
};

export default AllowancePlan;
