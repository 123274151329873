import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

// import { IEmployee } from 'app/shared/model/employee.model';
// import { getEntities as getEmployees } from 'app/entities/employee/employee.reducer';
import { createEntity, getEntity, reset, updateEntity } from './emp-reference.reducer';

export const EmpReferenceUpdate = () => {
  const account = useAppSelector(state => state.authentication.account);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  // const employees = useAppSelector(state => state.employee.entities);
  const empReferenceEntity = useAppSelector(state => state.empReference.entity);
  const loading = useAppSelector(state => state.empReference.loading);
  const updating = useAppSelector(state => state.empReference.updating);
  const updateSuccess = useAppSelector(state => state.empReference.updateSuccess);

  const handleClose = () => {
    navigate('/emp-reference');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.contactNo !== undefined && typeof values.contactNo !== 'number') {
      values.contactNo = Number(values.contactNo);
    }
    if (values.cnic !== undefined && typeof values.cnic !== 'number') {
      values.cnic = Number(values.cnic);
    }

    const entity = {
      ...empReferenceEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...empReferenceEntity,
          employee: empReferenceEntity?.employee?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h6 id="hrmsApp.empReference.home.createOrEditLabel" data-cy="EmpReferenceCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.empReference.home.createOrEditLabel">Create or edit a EmpReference</Translate>
          </h6>
        </Col>
      </Row>
      <Row className="justify-content-center m-2 text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('hrmsApp.empReference.fullName')}
                id="emp-reference-fullName"
                name="fullName"
                data-cy="fullName"
                className="row d-flex"
                labelClass="col-sm-4 text-start"
                inputClass="col-sm-8 custom-input flex-grow-1 form-control-sm"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empReference.organization')}
                id="emp-reference-organization"
                name="organization"
                data-cy="organization"
                className="row d-flex"
                labelClass="col-sm-4 text-start"
                inputClass="col-sm-8 custom-input flex-grow-1 form-control-sm"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empReference.designation')}
                id="emp-reference-designation"
                name="designation"
                data-cy="designation"
                className="row d-flex"
                labelClass="col-sm-4 text-start"
                inputClass="col-sm-8 custom-input flex-grow-1 form-control-sm"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empReference.linkage')}
                id="emp-reference-linkage"
                name="linkage"
                className="row d-flex"
                labelClass="col-sm-4 text-start"
                inputClass="col-sm-8 custom-input flex-grow-1 form-control-sm"
                data-cy="linkage"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empReference.email')}
                id="emp-reference-email"
                name="email"
                className="row d-flex"
                labelClass="col-sm-4 text-start"
                inputClass="col-sm-8 custom-input flex-grow-1 form-control-sm"
                data-cy="email"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empReference.contactNo')}
                id="emp-reference-contactNo"
                name="contactNo"
                className="row d-flex"
                labelClass="col-sm-4 text-start"
                inputClass="col-sm-8 custom-input flex-grow-1 form-control-sm"
                data-cy="contactNo"
                validate={{
                  // min: { value: 1000000000001, message: translate('entity.validation.min', { min: 1000000000001 }) },
                  // max: { value: 9999999999999, message: translate('entity.validation.max', { max: 9999999999999 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empReference.locatopn')}
                id="emp-reference-locatopn"
                className="row d-flex"
                labelClass="col-sm-4 text-start"
                inputClass="col-sm-8 custom-input flex-grow-1 form-control-sm"
                name="locatopn"
                data-cy="locatopn"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empReference.cnic')}
                id="emp-reference-cnic"
                name="cnic"
                data-cy="cnic"
                className="row d-flex"
                labelClass="col-sm-4 text-start"
                inputClass="col-sm-8 custom-input flex-grow-1 form-control-sm"
                type="text"
                validate={{
                  min: { value: 1000000000001, message: translate('entity.validation.min', { min: 1000000000001 }) },
                  max: { value: 9999999999999, message: translate('entity.validation.max', { max: 9999999999999 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/emp-reference" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EmpReferenceUpdate;
