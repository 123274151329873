import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './approval-log.reducer';

export const ApprovalLogStats = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const approvalLogs = useAppSelector(state => state.approvalLog.entities);
  const loading = useAppSelector(state => state.pendingApproval.loading);

  /* eslint-disable no-console */
  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  let approved=0;
  let rejected=0;

  useEffect(() => {
    console.log('---------- Last Page: ', localStorage.getItem('LAST_PAGE'));
    approved=approvalLogs.filter(e=>e.initiator.id===account?.employeeRef && e.action.codeName==='Approve').length;
    rejected=approvalLogs.filter(e=>e.initiator.id===account?.employeeRef && e.action.codeName==='Reject').length;

    const approvedDiv = document.getElementById('approved-fig');
    const rejectedDiv = document.getElementById('rejected-fig');

    if (approvedDiv && rejectedDiv) {
      approvedDiv.innerHTML=approved.toString();
      rejectedDiv.innerHTML=rejected.toString();
    }
  }, [approvalLogs]);

  return (
    (approvalLogs  ? (
      <div className={`border border-gray rounded bg-light text-center`}> <h5 className='text-center'>Your Submissions</h5>
          <div className='text-white bg-success px-2 py-1'>Approvals: <span id='approved-fig' className='bg-white text-primary px-2'>{approved}</span></div>
          <div className='text-white bg-danger px-2 py-1'> Rejections: <span id='rejected-fig' className='bg-white text-primary px-2'>{rejected}</span></div>
      </div>
        ) : ' '
    )
  )};

export default ApprovalLogStats;