import MenuItem from 'app/shared/layout/menus/menu-item';
import React from 'react';
import { NavDropdown } from './menu-components';

const HrMenuItems = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/employee">Employee  </MenuItem>
      <MenuItem icon="asterisk" to="/allowance-item">Allowance Item</MenuItem>
      <MenuItem icon="asterisk" to="/allowance-plan">Allowance Plan</MenuItem>
      <MenuItem icon="asterisk" to="/medical-plan">Medical Plan</MenuItem>
      <MenuItem icon="asterisk" to="/leaves-plan">Leaves Plan</MenuItem>
      <MenuItem icon="asterisk" to="/contract-template">Contract Template</MenuItem>
      <MenuItem icon="asterisk" to="/contract">Contract</MenuItem>
    </>
  );
};

export const HrMenu = () => (
  <NavDropdown icon="th-list" name="HR" id="entity-menu" data-cy="entity" style={{ maxHeight: '80vh', overflow: 'auto' }}>
    <HrMenuItems />
  </NavDropdown>
);
