import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './business-project.reducer';

export const BusinessProjectDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const businessProjectEntity = useAppSelector(state => state.businessProject.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="businessProjectDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.businessProject.detail.title">BusinessProject</Translate>
        </h5>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{businessProjectEntity.id}</dd>
          <dt>
            <span id="projectName">
              <Translate contentKey="hrmsApp.businessProject.projectName">Project Name</Translate>
            </span>
          </dt>
          <dd>{businessProjectEntity.projectName}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="hrmsApp.businessProject.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {businessProjectEntity.startDate ? (
              <TextFormat value={businessProjectEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="hrmsApp.businessProject.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {businessProjectEntity.endDate ? (
              <TextFormat value={businessProjectEntity.endDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="remarks">
              <Translate contentKey="hrmsApp.businessProject.remarks">Remarks</Translate>
            </span>
          </dt>
          <dd>{businessProjectEntity.remarks}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.projectType">Project Type</Translate>
          </dt>
          <dd>{businessProjectEntity.projectType ? businessProjectEntity.projectType.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.mainClient">Main Client</Translate>
          </dt>
          <dd>{businessProjectEntity.mainClient ? businessProjectEntity.mainClient.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.partnerClient">Partner Client</Translate>
          </dt>
          <dd>{businessProjectEntity.partnerClient ? businessProjectEntity.partnerClient.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.industry">Industry</Translate>
          </dt>
          <dd>{businessProjectEntity.industry ? businessProjectEntity.industry.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.engagementType">Engagement Type</Translate>
          </dt>
          <dd>{businessProjectEntity.engagementType ? businessProjectEntity.engagementType.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.engagementStatus">Engagement Status</Translate>
          </dt>
          <dd>{businessProjectEntity.engagementStatus ? businessProjectEntity.engagementStatus.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.department">Department</Translate>
          </dt>
          <dd>{businessProjectEntity.department ? businessProjectEntity.department.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.location">Location</Translate>
          </dt>
          <dd>{businessProjectEntity.location ? businessProjectEntity.location.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.projectManager">Project Manager</Translate>
          </dt>
          <dd>{businessProjectEntity.projectManager ? businessProjectEntity.projectManager.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessProject.adhocManager">Adhoc Manager</Translate>
          </dt>
          <dd>{businessProjectEntity.adhocManager ? businessProjectEntity.adhocManager.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/business-project" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/business-project/${businessProjectEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BusinessProjectDetail;
