import { IProjectTask } from 'app/shared/model/project-task.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface ITaskAssignment {
  id?: number;
  hoursAssigned?: number;
  remarks?: string | null;
  projectTask?: IProjectTask;
  employee?: IEmployee;
  status?: IHrCode;
}

export const defaultValue: Readonly<ITaskAssignment> = {};
