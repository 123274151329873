import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileModal from 'app/entities/profile-modal';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { getLoginUrl } from 'app/shared/util/url-utils';
import React, { useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router';
import { DropdownItem } from 'reactstrap';
import { NavDropdown } from './menu-components';


const AccountMenuItemsAuthenticated = () => {
  const [profileModalShow, setProfileModalShow] = useState(false);

  return (
    <>
      <DropdownItem icon="user" data-cy="profile" onClick={() => setProfileModalShow(true)}>
          <FontAwesomeIcon icon="user" fixedWidth className="text-danger" /> Profile
      </DropdownItem>
      <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
          <Translate contentKey="global.menu.account.logout">Sign out</Translate>
      </MenuItem>
      {profileModalShow && <ProfileModal show={profileModalShow} onHide={() => setProfileModalShow(false)} />}
    </>
  );
}

const AccountMenuItems = () => {
  const navigate = useNavigate();
  const pageLocation = useLocation();

  return (
    <>
      <DropdownItem
        id="login-item"
        tag="a"
        data-cy="login"
        onClick={() =>
          navigate(getLoginUrl(), {
            state: { from: pageLocation },
          })
        }
      >
        <FontAwesomeIcon icon="sign-in-alt" /> <Translate contentKey="global.menu.account.login">Sign in</Translate>
      </DropdownItem>
    </>
  );
};

export const AccountMenu = ({ isAuthenticated = false }) => (
  <NavDropdown icon="user" name={translate('global.menu.account.main')} id="account-menu" data-cy="accountMenu">
    {isAuthenticated ? <AccountMenuItemsAuthenticated /> : <AccountMenuItems />}
  </NavDropdown>
);

export default AccountMenu;
