import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntity, reset, updateEntity } from './country-workday.reducer';

export const CountryWorkdayUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const countryWorkdayEntity = useAppSelector(state => state.countryWorkday.entity);
  const loading = useAppSelector(state => state.countryWorkday.loading);
  const updating = useAppSelector(state => state.countryWorkday.updating);
  const updateSuccess = useAppSelector(state => state.countryWorkday.updateSuccess);

  const handleClose = () => {
    navigate('/country-workday');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getHrCodes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.timeZone !== undefined && typeof values.timeZone !== 'number') {
      values.timeZone = Number(values.timeZone);
    }

    const entity = {
      ...countryWorkdayEntity,
      ...values,
      country: hrCodes.find(it => it.id.toString() === values.country?.toString()),
      status: isNew ? hrCodes.find(e=>e.codeType==='WKS' && e.codeName==='Pending') : hrCodes.find(it => it.id.toString() === values.status?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...countryWorkdayEntity,
          country: countryWorkdayEntity?.country?.id,
          status: countryWorkdayEntity?.status?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 id="hrmsApp.countryWorkday.home.createOrEditLabel" data-cy="CountryWorkdayCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.countryWorkday.home.createOrEditLabel">Create/Modify a CountryWorkday</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {/* {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="country-workday-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null} */}
              <ValidatedField
                id="country-workday-country"
                name="country"
                data-cy="country"
                label={translate('hrmsApp.countryWorkday.country')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodes?
                  hrCodes.filter(outerEntity => outerEntity.codeType === 'COU' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                    </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.countryWorkday.monOn')}
                id="country-workday-monOn"
                name="monOn"
                data-cy="monOn"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.countryWorkday.tueOn')}
                id="country-workday-tueOn"
                name="tueOn"
                data-cy="tueOn"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.countryWorkday.wedOn')}
                id="country-workday-wedOn"
                name="wedOn"
                data-cy="wedOn"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.countryWorkday.thuOn')}
                id="country-workday-thuOn"
                name="thuOn"
                data-cy="thuOn"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.countryWorkday.friOn')}
                id="country-workday-friOn"
                name="friOn"
                data-cy="friOn"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.countryWorkday.satOn')}
                id="country-workday-satOn"
                name="satOn"
                data-cy="satOn"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.countryWorkday.sunOn')}
                id="country-workday-sunOn"
                name="sunOn"
                data-cy="sunOn"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.countryWorkday.timeZone')}
                id="country-workday-timeZone"
                name="timeZone"
                data-cy="timeZone"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.countryWorkday.remarks')}
                id="country-workday-remarks"
                name="remarks"
                data-cy="remarks"
                type="text"
              />
              {/* <ValidatedField
                id="country-workday-status"
                name="status"
                data-cy="status"
                label={translate('hrmsApp.countryWorkday.status')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodes?
                  hrCodes.filter(outerEntity => outerEntity.codeType === 'WKS' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                    </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/country-workday" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CountryWorkdayUpdate;
