import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './contract-template.reducer';

export const ContractTemplateDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const contractTemplateEntity = useAppSelector(state => state.contractTemplate.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="contractTemplateDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.contractTemplate.detail.title">ContractTemplate</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{contractTemplateEntity.id}</dd>
          <dt>
            <span id="hourlyBased">
              <Translate contentKey="hrmsApp.contractTemplate.hourlyBased">Hourly Based</Translate>
            </span>
          </dt>
          <dd>{contractTemplateEntity.hourlyBased ? 'true' : 'false'}</dd>
          <dt>
            <span id="allowance">
              <Translate contentKey="hrmsApp.contractTemplate.allowance">Allowance</Translate>
            </span>
          </dt>
          <dd>{contractTemplateEntity.allowance ? 'true' : 'false'}</dd>
          <dt>
            <span id="aip">
              <Translate contentKey="hrmsApp.contractTemplate.aip">Aip</Translate>
            </span>
          </dt>
          <dd>{contractTemplateEntity.aip ? 'true' : 'false'}</dd>
          <dt>
            <span id="gf">
              <Translate contentKey="hrmsApp.contractTemplate.gf">Gf</Translate>
            </span>
          </dt>
          <dd>{contractTemplateEntity.gf ? 'true' : 'false'}</dd>
          <dt>
            <span id="pf">
              <Translate contentKey="hrmsApp.contractTemplate.pf">Pf</Translate>
            </span>
          </dt>
          <dd>{contractTemplateEntity.pf ? 'true' : 'false'}</dd>
          <dt>
            <span id="onsiteAllowance">
              <Translate contentKey="hrmsApp.contractTemplate.onsiteAllowance">Onsite Allowance</Translate>
            </span>
          </dt>
          <dd>{contractTemplateEntity.onsiteAllowance ? 'true' : 'false'}</dd>
          <dt>
            <span id="performanceBonus">
              <Translate contentKey="hrmsApp.contractTemplate.performanceBonus">Performance Bonus</Translate>
            </span>
          </dt>
          <dd>{contractTemplateEntity.performanceBonus ? 'true' : 'false'}</dd>
          <dt>
            <span id="medical">
              <Translate contentKey="hrmsApp.contractTemplate.medical">Medical</Translate>
            </span>
          </dt>
          <dd>{contractTemplateEntity.medical ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contractTemplate.templateCode">Template Code</Translate>
          </dt>
          <dd>{contractTemplateEntity.templateCode ? contractTemplateEntity.templateCode.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contractTemplate.employmentType">Employment Type</Translate>
          </dt>
          <dd>{contractTemplateEntity.employmentType ? contractTemplateEntity.employmentType.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contractTemplate.payrollOffice">Payroll Office</Translate>
          </dt>
          <dd>{contractTemplateEntity.payrollOffice ? contractTemplateEntity.payrollOffice.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contractTemplate.currency">Currency</Translate>
          </dt>
          <dd>{contractTemplateEntity.currency ? contractTemplateEntity.currency.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contractTemplate.templateStatus">Template Status</Translate>
          </dt>
          <dd>{contractTemplateEntity.templateStatus ? contractTemplateEntity.templateStatus.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/contract-template" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/contract-template/${contractTemplateEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ContractTemplateDetail;
