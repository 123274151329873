import { IEmployee } from 'app/shared/model/employee.model';
import { relationInfo } from 'app/shared/model/enumerations/relation-info.model';

export interface IEmpIce {
  id?: number;
  fullName?: string;
  relationInfo?: keyof typeof relationInfo;
  contactNo?: number;
  curAddress?: string | null;
  employee?: IEmployee;
}

export const defaultValue: Readonly<IEmpIce> = {};
