import { CodeStatus } from 'app/shared/model/enumerations/code-status.model';

export interface IFcmTemplate {
  id?: number;
  topic?: string;
  title?: string;
  body?: string;
  image?: string | null;
  tmpStatus?: keyof typeof CodeStatus;
}

export const defaultValue: Readonly<IFcmTemplate> = {};
