import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EmpExperience from './emp-experience';
import EmpExperienceDeleteDialog from './emp-experience-delete-dialog';
import EmpExperienceDetail from './emp-experience-detail';
import EmpExperienceUpdate from './emp-experience-update';

const EmpExperienceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EmpExperience />} />
    <Route path="new" element={<EmpExperienceUpdate />} />
    <Route path=":id">
      <Route index element={<EmpExperienceDetail />} />
      <Route path="edit" element={<EmpExperienceUpdate />} />
      <Route path="delete" element={<EmpExperienceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmpExperienceRoutes;
