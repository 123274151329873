import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, ValidatedInput } from 'react-jhipster';
import { Button, Table, Tooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT } from 'app/config/constants';
import EmployeeModal from '../employee/employee-modal';
import HrCodeModal from '../hr-code/hr-code-modal';
import { getEntities, updateEntity as updateApproval } from './pending-approval.reducer';

export const PendingApprovalHome = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const pendingApprovalEntity = useAppSelector(state => state.pendingApproval.entity);

  const pendingApprovalList = useAppSelector(state => state.pendingApproval.entities);
  const loading = useAppSelector(state => state.pendingApproval.loading);

  const [tipRefresh, setTipRefresh] = useState(false);
  // useEffect(() => {
  //   if (tipRefresh) {
  //     const timeoutId = setTimeout(() => {
  //       setTipRefresh(false);
  //     }, 3000);
  //     // Clear the timeout when component unmounts or when tooltitipen changes to false
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [tipRefresh]);

  const [tipApprove, setTipApprove] = useState({});
  const toggleTipApprove = (rowId) => {
    setTipApprove((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const [tipReject, setTipReject] = useState({});
  const toggleTipReject = (rowId) => {
    setTipReject((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  /* eslint-disable no-console */
  useEffect(() => {
    // dispatch(getHrCodes({}));
    // dispatch(getEmployees({}));
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  const [editedRemarks, setEditedRemarks] = useState('');
  const handleAction = (values, vAction, vRemarks) => {
    console.log('------ Action: ', vAction, ' remarks: ', vRemarks);
    const entity = {
      ...pendingApprovalEntity,
      ...values,
      action: (vAction==='Approve') ? hrCodes.find(it => it.codeType === 'WKA' && it.codeName === 'Approve') : hrCodes.find(it => it.codeType === 'WKA' && it.codeName === 'Reject'),
      remarks: editedRemarks,
    };
    dispatch(updateApproval(entity));
  };

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedHrCode, setSelectedHrCode] = useState(null);
  const [isHrCodeModalOpen, setHrCodeModalOpen] = useState(false);
  const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);

  const toggleHrCodeModal = () => {
    setHrCodeModalOpen(!isHrCodeModalOpen);
  };
  const toggleEmployeeModal = () => {
    setEmployeeModalOpen(!isEmployeeModalOpen);
  };

  const showHrCodeModal = (detail) => {
    setSelectedHrCode(detail);
    console.log("----- Value of HrCode: ", detail);
    toggleHrCodeModal();
  };

  const showEmployeeModal = (detail) => {
    setSelectedEmployee(employees? employees.filter(e => e.id===detail.id) : detail);
    console.log("----- Value of Employee: ", employees? employees.filter(e => e.id===detail.id) : detail);
    toggleEmployeeModal();
  };

  return (
    <div className={`${pendingApprovalList && pendingApprovalList.length > 0 ? 'border border-gray rounded mt-4' : ''}`}>
    {pendingApprovalList && pendingApprovalList.length > 0 ? (
      <div>
        <h5 id="pending-approval-heading" data-cy="PendingApprovalHeading" className="text-center text-primary bg-light fw-bold p-2 m-0">
          <Translate contentKey="hrmsApp.pendingApproval.home.title">Pending Approvals</Translate>
          {/* <div className="d-flex justify-content-end"> */}
            <Button className="btn-sm me-2" color="light" onClick={handleSyncList} disabled={loading}
              id="refreshButton" 
              onMouseEnter={() => setTipRefresh(true)}
              onMouseLeave={() => setTipRefresh(false)}
            >
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            </Button>
            <Tooltip placement="bottom" isOpen={tipRefresh} target="refreshButton"><Translate contentKey="hrmsApp.pendingApproval.home.refreshListLabel">Refresh</Translate></Tooltip>
          {/* </div> */}
        </h5>
        <div className="table-responsive mt-0">
            <Table responsive>
              <thead>
                <tr className="table-info">
                  <th>
                    <Translate contentKey="hrmsApp.pendingApproval.dated">Dated</Translate>
                  </th>
                  <th>
                    <Translate contentKey="hrmsApp.pendingApproval.workflow">Workflow</Translate>
                  </th>
                  <th> Ref.# </th>
                  <th>
                    <Translate contentKey="hrmsApp.pendingApproval.initiator">Initiator</Translate>
                  </th>
                  <th>
                    <Translate contentKey="hrmsApp.pendingApproval.delegator">Delegator</Translate>
                  </th>
                  <th>Status
                    {/* <Translate contentKey="hrmsApp.pendingApproval.entityStatus">Entity Status</Translate> */}
                  </th>
                  <th>
                    <Translate contentKey="hrmsApp.pendingApproval.remarks">Remarks</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {pendingApprovalList.map((pendingApproval, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      {pendingApproval.dated ? (
                        <TextFormat type="date" value={pendingApproval.dated} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {pendingApproval.workflow ? ( 
                        <span className="text-primary" onClick={() => showHrCodeModal(pendingApproval.workflow)}>{pendingApproval.workflow.codeName} </span>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{pendingApproval.entityId}</td>
                    <td>
                      {pendingApproval.initiator ? (
                        <span className="text-primary" onClick={() => showEmployeeModal(pendingApproval.initiator)}>{pendingApproval.initiator.qlid} </span>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {pendingApproval.delegator ? (
                        <span className="text-primary" onClick={() => showEmployeeModal(pendingApproval.delegator)}>{pendingApproval.delegator.qlid} </span>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {pendingApproval.entityStatus ? (
                        <span className="text-primary" onClick={() => showHrCodeModal(pendingApproval.entityStatus)}>{pendingApproval.entityStatus.codeName} </span>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                    <ValidatedInput
                      id="pending-approval-remarks"
                      name="dated"
                      className="form-control-sm"
                      type="text"
                      placeholder="remarks"
                      onChange={(e) => setEditedRemarks(e.target.value)}
                      />
                      {/* <input
                        type="text"
                        // value={editedRemarks || pendingApproval.remarks}
                        onChange={(e) => setEditedRemarks(e.target.value)}
                      />
                      {pendingApproval.remarks} */}
                    </td>
                    <td className="text-end">
                    {pendingApproval.approver? pendingApproval.approver.id===account.employeeRef ? (
                        <div className="btn-group flex-btn-group-container">
                        <Button
                            onClick={() => handleAction(pendingApproval, 'Approve', editedRemarks || pendingApproval.remarks) }
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                            id={`approve-${pendingApproval.id}`} 
                            onMouseEnter={() => toggleTipApprove(pendingApproval.id)}
                            onMouseLeave={() => toggleTipApprove(pendingApproval.id)}
                          >
                            <FontAwesomeIcon icon={faCheck} /> 
                        </Button>
                        <Tooltip placement="bottom" isOpen={tipApprove[pendingApproval.id] || false} target={`approve-${pendingApproval.id}`}>Approve</Tooltip>
                        <Button
                            onClick={() => handleAction(pendingApproval, 'Reject', editedRemarks || pendingApproval.remarks) }
                            color="danger"
                            size="sm"
                            data-cy="entityDeleteButton"
                            id={`reject-${pendingApproval.id}`}
                            onMouseEnter={() => toggleTipReject(pendingApproval.id)}
                            onMouseLeave={() => toggleTipReject(pendingApproval.id)}
                          >
                            <FontAwesomeIcon icon={faClose} className='fw-bold'/> 
                        </Button>
                        <Tooltip placement="bottom" isOpen={tipReject[pendingApproval.id] || false} target={`reject-${pendingApproval.id}`}>Reject</Tooltip>
                      </div> 
                      ) : '' : ''
                    }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
        </div>
        {/* Display Modal */}
        {selectedHrCode && (
          <HrCodeModal hrCodeEntity={selectedHrCode} isOpen={isHrCodeModalOpen} toggle={toggleHrCodeModal} />
        )}
        {selectedEmployee && (
          <EmployeeModal employeeEntity={selectedEmployee} isOpen={isEmployeeModalOpen} toggle={toggleEmployeeModal} />
        )}
    </div>
    ) : ( null
      // !loading && (
      //   <div className="alert alert-warning">
      //     <Translate contentKey="hrmsApp.pendingApproval.home.notFound">No Pending Approvals found</Translate>
      //   </div>
      // )
    )}
    </div>
);
};
export default PendingApprovalHome;