import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './time-sheet.reducer';

export const TimeSheetDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const timeSheetEntity = useAppSelector(state => state.timeSheet.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="timeSheetDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.timeSheet.detail.title">TimeSheet</Translate>
        </h5>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{timeSheetEntity.id}</dd>
          <dt>
            <span id="dated">
              <Translate contentKey="hrmsApp.timeSheet.dated">Dated</Translate>
            </span>
          </dt>
          <dd>{timeSheetEntity.dated ? <TextFormat value={timeSheetEntity.dated} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="hoursSpent">
              <Translate contentKey="hrmsApp.timeSheet.hoursSpent">Hours Spent</Translate>
            </span>
          </dt>
          <dd>{timeSheetEntity.hoursSpent}</dd>
          <dt>
            <span id="activites">
              <Translate contentKey="hrmsApp.timeSheet.activites">Activites</Translate>
            </span>
          </dt>
          <dd>{timeSheetEntity.activites}</dd>
          <dt>
            <Translate contentKey="hrmsApp.timeSheet.employee">Employee</Translate>
          </dt>
          <dd>{timeSheetEntity.employee ? timeSheetEntity.employee.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.timeSheet.taskType">Task Type</Translate>
          </dt>
          <dd>{timeSheetEntity.taskType ? timeSheetEntity.taskType.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.timeSheet.assignment">Assignment</Translate>
          </dt>
          <dd>{timeSheetEntity.assignment ? timeSheetEntity.assignment.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.timeSheet.status">Status</Translate>
          </dt>
          <dd>{timeSheetEntity.status ? timeSheetEntity.status.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/time-sheet" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/time-sheet/${timeSheetEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TimeSheetDetail;
