import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import React from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const EmployeeModal = ({ employeeEntity, isOpen, toggle }) => {

    /* eslint no-console: off */
    console.log('---- Employee: ', employeeEntity[0]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="text-primary justify-text-center fw-bold text-primary">Detail</div> 
            </ModalHeader>
            <ModalBody>
                <Row className="justify-content-center">
                    <Col md="12">
                        <dl className="jh-entity-details bg-white">
                            {/* <dt> <span id="id"> <Translate contentKey="global.field.id">ID</Translate> </span> </dt>
                            <dd> {employeeEntity[0].id}</dd> */}
                            <dt> <Translate contentKey="hrmsApp.employee.employeeType">Employee Type</Translate> </dt>
                            <dd> {employeeEntity[0].employeeType ? employeeEntity[0].employeeType.codeName : ''}</dd>
                            <dt> <span id="firstName"> <Translate contentKey="hrmsApp.employee.firstName">First Name</Translate> </span> </dt>
                            <dd> {employeeEntity[0].firstName}</dd>
                            <dt> <span id="lastName"> <Translate contentKey="hrmsApp.employee.lastName">Last Name</Translate> </span> </dt>
                            <dd> {employeeEntity[0].lastName}</dd>
                            <dt> <span id="qlid"> <Translate contentKey="hrmsApp.employee.qlid">Qlid</Translate> </span> </dt>
                            <dd> {employeeEntity[0].qlid}</dd>
                            {/* <dt>
                                <span id="dob">
                                <Translate contentKey="hrmsApp.employee.dob">DOB</Translate>
                                </span>
                            </dt>
                            <dd>{employeeEntity.dob ? <TextFormat value={employeeEntity.dob} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}</dd> */}
                            <dt> <span id="joiningDate"> <Translate contentKey="hrmsApp.employee.joiningDate">Joining Date</Translate> </span> </dt>
                            <dd> {employeeEntity[0].joiningDate ? (
                                    <TextFormat value={employeeEntity[0].joiningDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                                ) : null}
                            </dd>
                            <dt> <Translate contentKey="hrmsApp.employee.user">User</Translate> </dt>
                            <dd> {employeeEntity[0].user ? employeeEntity[0].user.login : ''}</dd>
                            <dt> <Translate contentKey="hrmsApp.employee.reportingLine">Reporting Line</Translate> </dt>
                            <dd> {employeeEntity[0].reportingLine ? employeeEntity[0].reportingLine.firstName+" "+employeeEntity[0].reportingLine.lastName : ''} </dd>
                            <dt> <Translate contentKey="hrmsApp.employee.employeeStatus">Employee Status</Translate> </dt>
                            <dd> {employeeEntity[0].employeeStatus ? employeeEntity[0].employeeStatus.codeName : ''}</dd>
                        </dl>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default EmployeeModal;