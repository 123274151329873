import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './emp-experience.reducer';

export const EmpExperienceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const empExperienceEntity = useAppSelector(state => state.empExperience.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="empExperienceDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.empExperience.detail.title">EmpExperience</Translate>
        </h5>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{empExperienceEntity.id}</dd>
          <dt>
            <span id="organization">
              <Translate contentKey="hrmsApp.empExperience.organization">Organization</Translate>
            </span>
          </dt>
          <dd>{empExperienceEntity.organization}</dd>
          <dt>
            <span id="fromDate">
              <Translate contentKey="hrmsApp.empExperience.fromDate">From Date</Translate>
            </span>
          </dt>
          <dd>
            {empExperienceEntity.fromDate ? (
              <TextFormat value={empExperienceEntity.fromDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="toDate">
              <Translate contentKey="hrmsApp.empExperience.toDate">To Date</Translate>
            </span>
          </dt>
          <dd>
            {empExperienceEntity.toDate ? (
              <TextFormat value={empExperienceEntity.toDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastRole">
              <Translate contentKey="hrmsApp.empExperience.lastRole">Last Role</Translate>
            </span>
          </dt>
          <dd>{empExperienceEntity.lastRole}</dd>
          <dt>
            <span id="responsibility">
              <Translate contentKey="hrmsApp.empExperience.responsibility">Responsibility</Translate>
            </span>
          </dt>
          <dd>{empExperienceEntity.responsibility}</dd>
          <dt>
            <span id="achievement">
              <Translate contentKey="hrmsApp.empExperience.achievement">Achievement</Translate>
            </span>
          </dt>
          <dd>{empExperienceEntity.achievement}</dd>
          <dt>
            <span id="stillWorking">
              <Translate contentKey="hrmsApp.empExperience.stillWorking">Still Working</Translate>
            </span>
          </dt>
          <dd>{empExperienceEntity.stillWorking ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="hrmsApp.empExperience.employee">Employee</Translate>
          </dt>
          <dd>{empExperienceEntity.employee ? empExperienceEntity.employee.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/emp-experience" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/emp-experience/${empExperienceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmpExperienceDetail;
