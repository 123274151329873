import {
  faAngleDoubleRight,
  faBriefcase,
  faCalendar,
  faCertificate,
  faClose,
  faDesktop,
  faIdCardClip,
  faMoneyBill1Wave,
  faNotesMedical,
  faPeopleGroup,
  faShieldHeart,
  faStopwatch20,
  faThList,
  faUniversity,
  faUser,
  faUserGroup
} from '@fortawesome/free-solid-svg-icons';
import MenuItem from 'app/shared/layout/menus/sidemenu-item';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import { Nav, NavItem, UncontrolledCollapse } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'app/app.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity as getEmpProfile } from 'app/entities/emp-profile/emp-profile.reducer';
import { getEntities as getEmployees } from "app/entities/employee/employee.reducer";
import { getEntities as getHrCodes } from "app/entities/hr-code/hr-code.reducer";

const Sidebar = ({ onMenuItemClick }) => {
  const dispatch = useAppDispatch();
  // const empRef = account?.employeeRef;
  const account = useAppSelector(state => state.authentication.account);
  const empProfile = useAppSelector(state => state.empProfile.entity);
  const employees = useAppSelector(state => state.employee.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);

  /* eslint no-console: off */
  // PreLoad Required Entities
  useEffect(() => {
      if (hrCodes.length===0) {
          dispatch(getHrCodes({}));
      }
      if (employees.length===0) {
        dispatch(getEmployees({}));
      }
      if (account?.employeeRef!==null) {
        dispatch(getEmpProfile(account.employeeRef));
      }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
      setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      // Close the tooltip after 5 seconds
      const timeoutId = setTimeout(() => {
        setIsOpen(false);
      }, 5000);
      // Clear the timeout when component unmounts or when tooltipOpen changes to false
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen]);

  const handleMenuItemClick = (menuId) => {
    setIsOpen(false); // Collapse the sidebar when an item is clicked
    onMenuItemClick(menuId); // Call the provided onMenuItemClick function
  };
  
  return (
      <div className={`sidebar ${isOpen ? 'open' : ''}` /*`sidebar col-md-3 col-lg-2 d-md-block`*/}>
        <Nav vertical>
          <MenuItem id="dashboard" icon={faDesktop} to="/" label='Dashboard' isOpen={isOpen} level={0} onClick={() => handleMenuItemClick('dashboard')}>
            {' '}
            <Translate contentKey="global.menu.entities.home" />
          </MenuItem>
          <NavItem>
            <MenuItem id="submenu-profile" to="#" icon={faUser} label='Profile' isOpen={isOpen} level={0}>
              Profile
            </MenuItem>
            <UncontrolledCollapse toggler="#submenu-profile">
              <Nav vertical>
                <MenuItem id="profile" to="/emp-profile" icon={faThList} label="Personal Info" isOpen={isOpen} level={1}> Personal Info </MenuItem>
                <MenuItem id="qualification" to="/emp-qualification" icon={faUniversity} label='Qualification' isOpen={isOpen} level={1} > Qualification </MenuItem>
                <MenuItem id="certification" to="/emp-certification" icon={faCertificate} label='Certifications' isOpen={isOpen} level={1} > Certifications </MenuItem>
                <MenuItem id="experience" to="/emp-experience" icon={faBriefcase} label='Experience' isOpen={isOpen} level={1} > Experience </MenuItem>
                <MenuItem id="reference" to="/emp-reference" icon={faPeopleGroup} label='References' isOpen={isOpen} level={1} > References </MenuItem>
                <MenuItem id="dependents" to="/emp-dependent" icon={faUserGroup} label='Dependents' isOpen={isOpen} level={1} > Dependents </MenuItem>
                <MenuItem id="ice" to="/emp-ice" icon={faNotesMedical} label='Emergency Contact' isOpen={isOpen} level={1} > Emergency Contact </MenuItem>
                {/* <SubMenuItem id="profile" to="/emp-profile" icon={faThList} label="Personal Info" onClick={() => handleMenuItemClick('profile')}>  Personal Info </SubMenuItem>
                <SubMenuItem id="qualification" to="/emp-qualification" icon={faUniversity} label="Qualification" onClick={() => handleMenuItemClick('qualification')}> Qualification </SubMenuItem>
                <SubMenuItem id="certification" to="/emp-certification" icon={faCertificate} label="Certifications" onClick={() => handleMenuItemClick('certification')}> Certifications </SubMenuItem>
                <SubMenuItem id="experience" to="/emp-experience" icon={faBriefcase} label="Experience" onClick={() => handleMenuItemClick('experience')}> Experience </SubMenuItem>
                <SubMenuItem id="reference" to="/emp-reference" icon={faPeopleGroup} label="References" onClick={() => handleMenuItemClick('reference')}> References </SubMenuItem>
                <SubMenuItem id="dependents" to="/emp-dependent" icon={faUserGroup} label="Dependents" onClick={() => handleMenuItemClick('dependent')}> Dependents </SubMenuItem>
                <SubMenuItem id="ice" to="/emp-ice" icon={faNotesMedical} label="Emergency Contact" onClick={() => handleMenuItemClick('ice')}> Emergency Contact </SubMenuItem> */}
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <MenuItem id="submenu-selfcare" to="#" icon={faShieldHeart} label='Selfcare' isOpen={isOpen} level={0}  >
              Selfcare
            </MenuItem>
            <UncontrolledCollapse toggler="#submenu-selfcare">
              <Nav vertical>
                <MenuItem id="leaves" to="#leave" icon={faCalendar} label='Leaves' isOpen={isOpen} level={1} > Leaves </MenuItem>
                <MenuItem id="claims" to="#claim" icon={faIdCardClip} label='Claims' isOpen={isOpen} level={1} > Claims </MenuItem>
                <MenuItem id="timesheet" to="#timesheet" icon={faStopwatch20} label='Timesheet' isOpen={isOpen} level={1} > Timesheet </MenuItem>
                <MenuItem id="payslip" to="#payslip" icon={faMoneyBill1Wave} label='Payslip' isOpen={isOpen} level={1} > Payslip </MenuItem>
                {/* <SubMenuItem to="#leave" icon={faCalendar} label="Leaves">        Leaves                </SubMenuItem>
                <SubMenuItem to="#claim" icon={faIdCardClip} label="Claims">                  Claims                </SubMenuItem>
                <SubMenuItem to="#ts" icon={faStopwatch20} label="Timesheet">                  Timesheet                </SubMenuItem>
                <SubMenuItem to="#ps" icon={faMoneyBill1Wave} label="Payslip">                  Payslip                </SubMenuItem> */}
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
        </Nav>
        <button type="button" className="toggle-btn text-danger btn btn-sm py-0" onClick={toggleSidebar} >
          <h5><FontAwesomeIcon className={`mt-2 ${isOpen? 'text-right' : 'text-center'}`} icon={isOpen ? /* faBars faCog faArrowLeft faChevronLeft faCaretLeft faAngleDoubleLeft */ faClose : faAngleDoubleRight } /></h5>
        </button>
      </div>
  );
};

export default Sidebar;
