import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, ValidatedField, ValidatedForm, getSortState, isNumber, translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';

import { createEntity as createAssignment, getEntities as getAssignments, updateEntity as updateAssignment } from 'app/entities/task-assignment/task-assignment.reducer';
import { getEntities as getClients } from '../business-client/business-client.reducer';
import { getEntities as getProjects } from '../business-project/business-project.reducer';
import { createEntity as createTask, getEntities as getTasks, updateEntity as updateTask } from './project-task.reducer';

export const ProjectTask = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const employees = useAppSelector(state => state.employee.entities);
  const businessClients = useAppSelector(state => state.businessClient.entities);
  const businessProjectList = useAppSelector(state => state.businessProject.entities);
  const projectTaskList = useAppSelector(state => state.projectTask.entities);
  const taskAssignmentList = useAppSelector(state => state.taskAssignment.entities);
  const loading = useAppSelector(state => state.projectTask.loading);

  const getAllEntities = () => {
    dispatch(
      getTasks({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  // START CUSTOM
  useEffect(() => {
    dispatch(getClients({}));
    dispatch(getProjects({sort:'id,desc'}));
    dispatch(getAssignments({}));
  }, []);

  /* eslint no-console: off */
  const [selectedOption, setSelectedOption] = useState<number>();
  const [isNewTask, setIsNewTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(projectTaskList.find(e=>e.id===-1));
  const [isAssignment, setIsAssignment] = useState(false);
  const [isNewAssignment, setIsNewAssignment] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(taskAssignmentList.find(e=>e.id===-1));
  
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const updatingTask = useAppSelector(state => state.projectTask.updating);
  const updateSuccessTask = useAppSelector(state => state.projectTask.updateSuccess);
  const updatingAssignment = useAppSelector(state => state.taskAssignment.updating);
  const updateSuccessAssignment = useAppSelector(state => state.taskAssignment.updateSuccess);

  const handleSelectionChange = (event) => {
    console.log('------------- selectedId: ', event.target.value);
    handleCloseTask();
    setSelectedOption(Number.isNaN(parseInt(event.target.value, 10)) ? null : parseInt(event.target.value, 10));
  };

  const handleCloseTask = () => {
    setIsNewTask(false);
    setSelectedTask(null);
    setIsAssignment(false);
    setIsNewAssignment(false);
    setSelectedAssignment(null);
  };

  const handleCloseAssignment = () => {
    setSelectedTask(null);
    setIsAssignment(false);
    setIsNewAssignment(false);
    setSelectedAssignment(null);
  };

  const handleCloseNewAssignment = () => {
    setIsNewAssignment(false);
    setSelectedAssignment(null);
  };

  useEffect(() => {
    if (updateSuccessTask) {
      handleCloseTask();
    }
  }, [updateSuccessTask]);

  useEffect(() => {
    if (updateSuccessAssignment) {
      handleCloseNewAssignment();
    }
  }, [updateSuccessAssignment]);

  // eslint-disable-next-line complexity
  // const handleNewTask = () => {
  //   setIsNewTask(true);
  // };

  const handleAssignment = (selectedId) => {
    setIsAssignment(true);
    setSelectedTask(projectTaskList.find(e=>e.id===selectedId));
  };

  const handleIsNewAssignment = () => {
    setIsNewAssignment(true);
  };

  const handleEditTask = (selectedId) => {
    setSelectedTask(projectTaskList.find(e=>e.id===selectedId));
    console.log('-------- SelectedTask: ', selectedId, ' --- ', isNewTask);
  };

  const handleEditAssignment = (selectedId) => {
    setSelectedAssignment(taskAssignmentList.find(e=>e.id===selectedId));
    console.log('-------- SelectedAssignment: ', selectedId, ' --- ', isNewAssignment);
  };

  // eslint-disable-next-line complexity
  const saveTask = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...selectedTask,
      ...values,
      parentTask: projectTaskList.find(it => it.id.toString() === values.parentTask?.toString()),
      dependsOn: projectTaskList.find(it => it.id.toString() === values.dependsOn?.toString()),
      businessProject: isNewTask? businessProjectList.find(e=>e.id===selectedOption) : businessProjectList.find(it => it.id.toString() === values.businessProject?.toString()),
      status: isNewTask? hrCodes.find(e=>e.codeType==='WKS' && e.codeName==='Active') : hrCodes.find(it => it.id.toString() === values.status?.toString()),
    };

    // Entity Level Validations
    if (new Date(entity.endDate) < new Date(entity.startDate)) {
      document.getElementById('project-task-message').innerHTML= '<span class="col-md-8 bg-danger">End Date cannot be before Start Date</span>';
      return;
    }
    if (new Date(entity.startDate) < new Date(entity.businessProject.startDate) || new Date(entity.startDate) > new Date(entity.businessProject.endDate)
      || new Date(entity.endDate) < new Date(entity.businessProject.startDate) || new Date(entity.endDate) > new Date(entity.businessProject.endDate)) {
      document.getElementById('project-task-message').innerHTML= '<span class="col-md-8 bg-danger">Task Dates can be between Project Strat Date: '
                                                                  +entity.businessProject.startDate+" and End Date: "+entity.businessProject.endDate+'</span>';
        // alert("Task dats can be between project dates only i.e. "+entity.businessProject.startDate+" and "+entity.businessProject.endDate);
      return; //translate('entity.validation.dateOrder');
    }
    console.log('---------- Entity: ',entity);
    if (isNewTask) {
      dispatch(createTask(entity));
    } else {
      dispatch(updateTask(entity));
    }
    handleCloseTask();
  };

  const defaultValuesTask = () =>
    isNewTask ? {}
      : {
          ...selectedTask,
          businessProject: selectedTask?.businessProject?.id,
          parentTask: selectedTask?.parentTask?.id,
          dependsOn: selectedTask?.dependesOn?.id,
          status: selectedTask?.status?.id,
        };

  // eslint-disable-next-line complexity
  const saveAssignment = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.hoursAssigned !== undefined && typeof values.hoursAssigned !== 'number') {
      values.hoursAssigned = Number(values.hoursAssigned);
    }

    // console.log('------------ Assignment 0: ', selectedTask); //projectTaskList.find(e=>e.projectTask.id===selectedTask.id)
    const entity = {
      ...selectedAssignment,
      ...values,
      employee: employees.find(it => it.id.toString() === values.employee?.toString()),
      projectTask: isNewAssignment? projectTaskList.find(e=>e.id===selectedTask.id) : projectTaskList.find(it => it.id.toString() === values.projectTask?.toString()),
      status: isNewAssignment? hrCodes.find(it => it.codeType==='WKS' && it.codeName==='Active') : hrCodes.find(it => it.id.toString() === values.status?.toString()),
    };

    console.log('------------ Assignment: ', isNewAssignment, '---', entity);
    if (isNewAssignment) {
      dispatch(createAssignment(entity));
    } else {
      dispatch(updateAssignment(entity));
    }
    handleCloseNewAssignment();
  };

  const defaultValuesAssignment = () =>
    isNewAssignment
      ? {}
      : {
          ...selectedAssignment,
          projectTask: selectedAssignment?.projectTask?.id,
          employee: selectedAssignment?.employee?.id,
          status: selectedAssignment?.status?.id,
      };


  // End CUSTOM

  return (
    <div>
      <h5 id="country-heading" className="text-center text-primary fw-bold p-1">
        Business Projects
      </h5>
      <div className="container mb-5">
        {hrCodes && businessClients && businessProjectList && businessProjectList.length > 0 ? (
            <ValidatedField name="mainSelection" type="select" value={selectedOption} onChange={handleSelectionChange}>
              <option value="" key="0" />
              {businessProjectList.filter(e=>e.engagementStatus.id===hrCodes.find(it => it.codeType==='WKS' && it.codeName==='Active')?.id).sort((a, b) => a.startDate - b.startDate)  /*a.projectName.localeCompare(b.projectName))*/.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.startDate} - {otherEntity.projectName} - 
                        {businessClients.find(e=>e.id===otherEntity.mainClient.id)?.clientName} 

                        ({hrCodes.find(e=>e.id===(businessClients.find(t=>t.id===otherEntity.mainClient.id)?.country.id))?.codeName}) 
                        {otherEntity.partnerClient ? `- (${businessClients.find(e=>e.id===otherEntity.partnerClient.id)?.clientName})` : ''}
                         {/* {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''} */}
                      </option>
                  ))}
            </ValidatedField>
          ) : !loading && ( <div className="alert alert-warning"> No Data found </div> )
        }
        {selectedOption? (
          <>
            <h5 id="project-task-heading" data-cy="ProjectTaskHeading"  className="bg-light text-center text-primary fw-bold p-1 mt-5">
                Project Tasks ({businessProjectList.find(e => e.id === selectedOption)?.projectName})
                <div className="d-flex justify-content-end">
                  <Button id="add-entity" className="me-2" color="primary" onClick={()=>setIsNewTask(true)} disabled={loading || (isNewTask || selectedTask!==null)}>
                    <FontAwesomeIcon icon="plus" spin={loading} />{' '}
                  </Button>
                </div>
            </h5>
            <div className="table-hover">
              {projectTaskList && projectTaskList.filter(e=>e.businessProject.id===selectedOption).length > 0 ? (
                <Table hover>
                  <thead>
                    <tr>
                      {/* <th className="hand" onClick={sort('id')}>
                        <Translate contentKey="hrmsApp.projectTask.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                      </th> */}
                      <th className="hand" onClick={sort('taskName')}>
                        <Translate contentKey="hrmsApp.projectTask.taskName">Task Name</Translate>{' '}
                        <FontAwesomeIcon icon={getSortIconByFieldName('taskName')} />
                      </th>
                      <th className="hand" onClick={sort('taskDescription')}>
                        <Translate contentKey="hrmsApp.projectTask.taskDescription">Task Description</Translate>{' '}
                        <FontAwesomeIcon icon={getSortIconByFieldName('taskDescription')} />
                      </th>
                      <th className="hand" onClick={sort('startDate')}>
                        <Translate contentKey="hrmsApp.projectTask.startDate">Start Date</Translate>{' '}
                        <FontAwesomeIcon icon={getSortIconByFieldName('startDate')} />
                      </th>
                      <th className="hand" onClick={sort('endDate')}>
                        <Translate contentKey="hrmsApp.projectTask.endDate">End Date</Translate>{' '}
                        <FontAwesomeIcon icon={getSortIconByFieldName('endDate')} />
                      </th>
                      <th className="hand" onClick={sort('billable')}>
                        <Translate contentKey="hrmsApp.projectTask.billable">Billable?</Translate>{' '}
                        <FontAwesomeIcon icon={getSortIconByFieldName('billable')} />
                      </th>
                      <th> <Translate contentKey="hrmsApp.projectTask.parentTask">Parent Task</Translate>  </th>
                      <th> <Translate contentKey="hrmsApp.projectTask.dependsOn">Dependes On</Translate> </th>
                      <th> <Translate contentKey="hrmsApp.projectTask.status">Status</Translate> </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {projectTaskList.filter(e=>e.businessProject.id===selectedOption).sort((a,b) => a.startDate - b.startDate && a.endDate - b.endDate).map((projectTask, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable" className={selectedTask?.id===projectTask.id? 'table-active' : ''}>
                        {/* <td>
                          <Button tag={Link} to={`/project-task/${projectTask.id}`} color="link" size="sm">
                            {projectTask.id}
                          </Button>
                        </td> */}
                        <td>{projectTask.taskName}</td>
                        <td>{projectTask.taskDescription}</td>
                        <td>
                          {projectTask.startDate ? <TextFormat type="date" value={projectTask.startDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                        </td>
                        <td>
                          {projectTask.endDate ? <TextFormat type="date" value={projectTask.endDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                        </td>
                        <td>
                          {projectTask.billable ? '✔' : 'X' }
                        </td>
                        <td>
                          {projectTask.parentTask ? projectTaskList.find(e=>e.id===projectTask.parentTask.id)?.taskName : '' }
                        </td>
                        <td>
                          {projectTask.dependsOn ? projectTaskList.find(e=>e.id===projectTask.dependsOn.id)?.taskName : '' }
                        </td>
                        <td>{projectTask.status ? hrCodes.find(e=>e.id===projectTask.status.id)?.codeName : ''}</td>
                        <td className="text-end">
                          <div className="btn-group flex-btn-group-container">
                            {projectTask.parentTask===null? null :
                              <Button id="assign-task" onClick={()=>handleAssignment(projectTask.id)} color="success" size="sm" data-cy="entityAssignButton" disabled={isNewTask || selectedTask!==null || projectTask.parentTask===null}>
                                <FontAwesomeIcon icon='user' />{' '}
                              </Button>
                            }
                            <Button id="edit-task-entity" onClick={() => handleEditTask(projectTask.id)} color="info" size="sm" data-cy="entityEditButton" disabled={isNewTask || selectedTask!==null}>
                              <FontAwesomeIcon icon="pencil-alt" />{' '}
                            </Button>
                            <Button onClick={() => (window.location.href = `/project-task/${projectTask.id}/delete`)} color="danger" size="sm" data-cy="entityDeleteButton" disabled={isNewTask || selectedTask!==null}>
                                <FontAwesomeIcon icon="trash" />{' '}
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning">
                    <Translate contentKey="hrmsApp.projectTask.home.notFound">No Project Tasks found</Translate>
                  </div>
                )
              )}
            </div>
            {(isNewTask || (selectedTask!==null && !isAssignment)) ? (
              <>
                <Row className="justify-content-center text-primary fw-bold bg-light pt-2 m-0">
                  <Col md="8">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <ValidatedForm defaultValues={defaultValuesTask()} onSubmit={saveTask}>
                        <ValidatedField
                          label={translate('hrmsApp.projectTask.taskName')}
                          id="project-task-taskName"
                          name="taskName"
                          data-cy="taskName"
                          type="text"
                          validate={{
                            required: { value: true, message: translate('entity.validation.required') },
                            maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                          }}
                        />
                        <ValidatedField
                          label={translate('hrmsApp.projectTask.taskDescription')}
                          id="project-task-taskDescription"
                          name="taskDescription"
                          data-cy="taskDescription"
                          type="text"
                          // validate={{
                          //   required: { value: true, message: translate('entity.validation.required') },
                          // }}
                        />
                        <ValidatedField
                          label={translate('hrmsApp.projectTask.startDate')}
                          id="project-task-startDate"
                          name="startDate"
                          data-cy="startDate"
                          type="date"
                          validate={{
                            required: { value: true, message: translate('entity.validation.required') },
                          }}
                        />
                        <ValidatedField
                          label={translate('hrmsApp.projectTask.endDate')}
                          id="project-task-endDate"
                          name="endDate"
                          data-cy="endDate"
                          type="date"
                          validate={{
                            required: { value: true, message: translate('entity.validation.required') },
                          }}
                        />
                        <ValidatedField
                          label={translate('hrmsApp.projectTask.billable')}
                          id="project-task-billable"
                          name="billable"
                          data-cy="billable"
                          check
                          type="checkbox"
                        />
                        <ValidatedField
                          id="project-task-parentTask"
                          name="parentTask"
                          data-cy="parentTask"
                          label={translate('hrmsApp.projectTask.parentTask')}
                          type="select"
                        >
                          <option value="" key="0" />
                          {projectTaskList
                            ? projectTaskList.filter(e => e.parentTask===null && selectedTask?.id !== e.id && e.businessProject.id===selectedOption).map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.taskName} ({otherEntity.taskDescription})
                                </option>
                              ))
                            : null}
                        </ValidatedField>
                        <ValidatedField
                          id="project-task-dependsOn"
                          name="dependsOn"
                          data-cy="dependsOn"
                          label={translate('hrmsApp.projectTask.dependsOn')}
                          type="select"
                        >
                          <option value="" key="0" />
                          {projectTaskList
                            ? projectTaskList.filter(e => e.parentTask!==null && selectedTask?.id !== e.id && e.businessProject.id===selectedOption).map(otherEntity => (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.taskName} {otherEntity.taskDescription ? `(${otherEntity.taskDescription})` : ''}
                              </option>
                            ))
                          : null}
                        </ValidatedField>
                        <Button color="info" size="sm" id="cancel-save" data-cy="entityCreateCancelButton" onClick={handleCloseTask}><FontAwesomeIcon icon="arrow-left" />&nbsp;</Button>
                          &nbsp;
                        <Button color="primary"  size="sm" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updatingTask}><FontAwesomeIcon icon="save" />&nbsp;</Button>
                      </ValidatedForm>
                    )}
                  </Col>
                </Row>
                <Row id="project-task-message" className="justify-content-center bg-light text-white m-0 py-1"></Row>
              </>
              ) : null
            }
            {(isAssignment && selectedTask!=null) ? (
              <>
                <h5 id="task-assignment-heading" data-cy="TaskAssignmentHeading" className="bg-light text-center text-primary fw-bold mt-5 p-1">
                    Task Assignment ({projectTaskList.find(e=>e.id===selectedTask.id)['taskName']})
                  <div className="d-flex justify-content-end">
                    <Button className="me-2" color="info" onClick={handleCloseAssignment} disabled={loading}> <FontAwesomeIcon icon="arrow-left" spin={loading} />{' '} </Button>
                    <Button id="add-entity" className="me-2" color="primary" onClick={handleIsNewAssignment} disabled={loading || (isNewAssignment || selectedAssignment!==null)}>
                      <FontAwesomeIcon icon="plus" spin={loading} />{' '}
                    </Button>
                  </div>
                </h5>
                <div className="table-hover">
                    {taskAssignmentList && taskAssignmentList.filter(e=>e.projectTask.id===selectedTask.id).length > 0 ? (
                      <Table hover>
                        <thead>
                          <tr>
                            <th> <Translate contentKey="hrmsApp.taskAssignment.id">ID</Translate> </th> 
                            <th> <Translate contentKey="hrmsApp.taskAssignment.employee">Employee</Translate> <FontAwesomeIcon icon="sort" /> </th>
                            <th> <Translate contentKey="hrmsApp.taskAssignment.hoursAssigned">Hours Assigned</Translate>{' '} </th>
                            <th> <Translate contentKey="hrmsApp.taskAssignment.remarks">Remarks</Translate>{' '} </th>
                            {/* <th> <Translate contentKey="hrmsApp.taskAssignment.projectTask">Project Task</Translate> <FontAwesomeIcon icon="sort" /> </th> */}
                            <th> <Translate contentKey="hrmsApp.taskAssignment.status">Status</Translate> <FontAwesomeIcon icon="sort" /> </th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {taskAssignmentList.filter(e=>e.projectTask.id===selectedTask.id).map((taskAssignment, i) => (
                            <tr key={`entity-${i}`} data-cy="entityTable" className={selectedAssignment?.id===taskAssignment.id? 'table-active' : ''}>
                              <td>{taskAssignment.id}</td>
                              <td>{taskAssignment.employee ? `${employees.find(e=>e.id===taskAssignment.employee.id)?.firstName} ${employees.find(e=>e.id===taskAssignment.employee.id)?.lastName}`  : ''}</td>
                              <td>{taskAssignment.hoursAssigned}</td>
                              <td>{taskAssignment.remarks}</td>
                              {/* <td>{taskAssignment.projectTask ? taskAssignment.projectTask.id : '' }</td> */}
                              <td>{taskAssignment.status ? hrCodes.find(e=>e.id===taskAssignment.status.id)?.codeName : ''}</td>
                              <td className="text-end">
                                <div className="btn-group flex-btn-group-container">
                                  <Button id="btn-edit-assignment" onClick={() => handleEditAssignment(taskAssignment.id)} color="info" size="sm" disabled={isNewAssignment || selectedAssignment!==null}>
                                    <FontAwesomeIcon icon="pencil-alt" />{' '}
                                  </Button>
                                  <Button onClick={() => (window.location.href = `/task-assignment/${taskAssignment.id}/delete`)} color="danger" size="sm" data-cy="assignmentDeleteButton" disabled={isNewAssignment || selectedAssignment!==null}>
                                      <FontAwesomeIcon icon="trash" />{' '}
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      !loading && (
                        <div className="alert alert-warning">
                          <Translate contentKey="hrmsApp.taskAssignment.home.notFound">No Task Assignments found</Translate>
                        </div>
                      )
                    )}
                </div>
                {(isNewAssignment || selectedAssignment!==null) ? (
                  <Row className="justify-content-center bg-light py-2 m-0">
                    <Col md="8">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <ValidatedForm defaultValues={defaultValuesAssignment()} onSubmit={saveAssignment}>
                          {!isNewAssignment ? (
                            <ValidatedField
                              name="id"
                              required
                              readOnly
                              id="task-assignment-id"
                              label={translate('global.field.id')}
                              validate={{ required: true }}
                            />
                          ) : null}
                          <ValidatedField
                            id="task-assignment-employee"
                            name="employee"
                            data-cy="employee"
                            label={translate('hrmsApp.taskAssignment.employee')}
                            type="select"
                            required
                          >
                            <option value="" key="0" />
                            {employees
                              ? employees.filter(e=>e.employeeStatus.codeName==='Joined' || e.employeeStatus.codeName==='Active').sort((a, b) => a.id - b.id).map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.firstName} {otherEntity.lastName} {otherEntity.user? '('+otherEntity.user.login+')' : '' }
                                  </option>
                                ))
                              : null}
                          </ValidatedField>
                          <ValidatedField
                            label={translate('hrmsApp.taskAssignment.hoursAssigned')}
                            id="task-assignment-hoursAssigned"
                            name="hoursAssigned"
                            data-cy="hoursAssigned"
                            type="text"
                            validate={{
                              required: { value: true, message: translate('entity.validation.required') },
                              max: { value: 2000, message: translate('entity.validation.max', { max: 2000 }) },
                              validate: v => isNumber(v) || translate('entity.validation.number'),
                            }}
                          />
                          <ValidatedField
                            label={translate('hrmsApp.taskAssignment.remarks')}
                            id="task-assignment-remarks"
                            name="remarks"
                            data-cy="remarks"
                            type="text"
                          />
                          <Button color="info" size="sm" id="cancel-save" data-cy="entityCreateCancelButton" onClick={handleCloseNewAssignment}><FontAwesomeIcon icon="arrow-left" />&nbsp;</Button>
                            &nbsp;
                          <Button color="primary"  size="sm" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updatingAssignment}><FontAwesomeIcon icon="save" />&nbsp;</Button>
                        </ValidatedForm>
                      )}
                    </Col>
                  </Row>
                ) : null
                }
              </>
              ) : null
            }
          </>
          ) : null 
        }
    </div>
  </div>
  )
};

export default ProjectTask;
