import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './emp-profile.reducer';

export const EmpProfileDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const empProfileEntity = useAppSelector(state => state.empProfile.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="empProfileDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.empProfile.detail.title">EmpProfile</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.id}</dd>
          <dt>
            <span id="maritalStatus">
              <Translate contentKey="hrmsApp.empProfile.maritalStatus">Marital Status</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.maritalStatus}</dd>
          <dt>
            <span id="cnic">
              <Translate contentKey="hrmsApp.empProfile.cnic">Cnic</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.cnic}</dd>
          <dt>
            <span id="cnicIssueDate">
              <Translate contentKey="hrmsApp.empProfile.cnicIssueDate">Cnic Issue Date</Translate>
            </span>
          </dt>
          <dd>
            {empProfileEntity.cnicIssueDate ? (
              <TextFormat value={empProfileEntity.cnicIssueDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="cnicExpiryDate">
              <Translate contentKey="hrmsApp.empProfile.cnicExpiryDate">Cnic Expiry Date</Translate>
            </span>
          </dt>
          <dd>
            {empProfileEntity.cnicExpiryDate ? (
              <TextFormat value={empProfileEntity.cnicExpiryDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="cnicUrl">
              <Translate contentKey="hrmsApp.empProfile.cnicUrl">Cnic Url</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.cnicUrl}</dd>
          <dt>
            <span id="passportNo">
              <Translate contentKey="hrmsApp.empProfile.passportNo">Passport No</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.passportNo}</dd>
          <dt>
            <span id="passportIssueDate">
              <Translate contentKey="hrmsApp.empProfile.passportIssueDate">Passport Issue Date</Translate>
            </span>
          </dt>
          <dd>
            {empProfileEntity.passportIssueDate ? (
              <TextFormat value={empProfileEntity.passportIssueDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="passportExpiryDate">
              <Translate contentKey="hrmsApp.empProfile.passportExpiryDate">Passport Expiry Date</Translate>
            </span>
          </dt>
          <dd>
            {empProfileEntity.passportExpiryDate ? (
              <TextFormat value={empProfileEntity.passportExpiryDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="passportUrl">
              <Translate contentKey="hrmsApp.empProfile.passportUrl">Passport Url</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.passportUrl}</dd>
          <dt>
            <span id="personalMobile">
              <Translate contentKey="hrmsApp.empProfile.personalMobile">Personal Mobile</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.personalMobile}</dd>
          <dt>
            <span id="personalLandline">
              <Translate contentKey="hrmsApp.empProfile.personalLandline">Personal Landline</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.personalLandline}</dd>
          <dt>
            <span id="personalEmailAddress">
              <Translate contentKey="hrmsApp.empProfile.personalEmailAddress">Personal Email Address</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.personalEmailAddress}</dd>
          <dt>
            <span id="curAddressLine1">
              <Translate contentKey="hrmsApp.empProfile.curAddressLine1">Cur Address Line 1</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.curAddressLine1}</dd>
          <dt>
            <span id="curAddressLine2">
              <Translate contentKey="hrmsApp.empProfile.curAddressLine2">Cur Address Line 2</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.curAddressLine2}</dd>
          <dt>
            <span id="permAddressLine1">
              <Translate contentKey="hrmsApp.empProfile.permAddressLine1">Perm Address Line 1</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.permAddressLine1}</dd>
          <dt>
            <span id="permAddressLine2">
              <Translate contentKey="hrmsApp.empProfile.permAddressLine2">Perm Address Line 2</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.permAddressLine2}</dd>
          <dt>
            <span id="profilePicUrl">
              <Translate contentKey="hrmsApp.empProfile.profilePicUrl">Profile Pic Url</Translate>
            </span>
          </dt>
          <dd>{empProfileEntity.profilePicUrl}</dd>
          <dt>
            <Translate contentKey="hrmsApp.empProfile.gender">Gender</Translate>
          </dt>
          <dd>{empProfileEntity.gender ? empProfileEntity.gender.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.empProfile.employee">Employee</Translate>
          </dt>
          <dd>{empProfileEntity.employee ? empProfileEntity.employee.qlid : ''}</dd>
        </dl>
        <Button tag={Link} to="/emp-profile" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/emp-profile/${empProfileEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmpProfileDetail;
