import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

// import { getEntitiesList as getEmployees } from 'app/entities/employee/employee.reducer';
import { getUsers } from 'app/shared/reducers/user-management';
import { createEntity, getEntity, reset, updateEntity } from './employee.reducer';

export const EmployeeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const employees = useAppSelector(state => state.employee.entities);
  const employeeEntity = useAppSelector(state => state.employee.entity);
  const loading = useAppSelector(state => state.employee.loading);
  const updating = useAppSelector(state => state.employee.updating);
  const updateSuccess = useAppSelector(state => state.employee.updateSuccess);
  // const nextId 

  const handleClose = () => {
    navigate('/employee' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getUsers({}));
    // dispatch(getHrCodes({}));
    // dispatch(getEmployees({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  // Added by Shafqat
  const [employeeType, setEmployeeType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [qlid, setQlid] = useState('');

  useEffect(() => {
    if (firstName && lastName) {
      const firstInitial = firstName.charAt(0).toUpperCase();
      const lastInitial = lastName.charAt(0).toUpperCase();
      if (employeeType) {
        setQlid(`${firstInitial}${lastInitial}${employeeType}`);
      } else {
        setQlid(`${firstInitial}${lastInitial}`);
      }
    } else {
      setQlid(''); // Set qlid to an empty string if either firstName or lastName is missing
    }
  }, [firstName, lastName]);
  // End Added
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...employeeEntity,
      ...values,
      employeeType: hrCodes.find(it => it.id.toString() === values.employeeType.toString()),
      employeeStatus: isNew? hrCodes.find(e=>e.codeType==='EMS' && e.codeName==='Pending') : hrCodes.find(it => it.id.toString() === values.employeeStatus.toString()),
      user: isNew ? null : users.find(it => it.id.toString() === values.user.toString()),
      reportingLine: isNew ? null : employees.find(it => it.id.toString() === values.reportingLine.toString()),
    };
    // if (new Date(entity.endDate) < new Date(entity.startDate)) {
    /* eslint no-console: off */
    const cutDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18)), dob = new Date(entity.dob.toString());
    console.log('------------ CurDate: ', new Date(), '---', new Date(dob.setFullYear(dob.getFullYear() + 18)));
    if (new Date(entity.dob) > cutDate) {
      document.getElementById('hr-code-message').innerHTML= '<span class="col-md-8 bg-danger">DoB can\'t be greater than '+cutDate.toLocaleDateString()+'</span>';
      return;
    }
    if (entity.joiningDate > new Date() || entity.joiningDate < new Date(dob.setFullYear(dob.getFullYear() + 18))) {
      document.getElementById('hr-code-message').innerHTML= '<span class="col-md-8 bg-danger">Joining Date can\'t be in future or before 18 years of age.</span>';
      return;
    }
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...employeeEntity,
          employeeType: employeeEntity?.employeeType?.id,
          user: employeeEntity?.user?.id,
          reportingLine: employeeEntity?.reportingLine?.id,
          employeeStatus: employeeEntity?.employeeStatus?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 id="hrmsApp.employee.home.createOrEditLabel" data-cy="EmployeeCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.employee.home.createOrEditLabel">Create or edit a Employee</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="employee-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                id="employee-employeeType"
                name="employeeType"
                data-cy="employeeType"
                label={translate('hrmsApp.employee.employeeType') + '*'}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                onChange={e => {
                  // Added by Shafqat
                  // const selectedHrCode = hrCodes.find(hrCode => hrCode.id.toString() === e.target.value);
                  // const codeDescription = selectedHrCode ? selectedHrCode.codeDescription.match(/\(([^)]+)\)/)[1] : '';
                  // setEmployeeType(codeDescription);
                  // //  setQlid(`${employeeEntity?.firstName || ''}-${employeeEntity?.lastName || ''}-${employeeTypeValue}`);
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'EMT' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.employee.firstName') + '*'}
                id="employee-firstName"
                name="firstName"
                data-cy="firstName"
                type="text"
                // onChange={e => setFirstName(e.target.value)} // Added by Shafqat
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.employee.lastName') + '*'}
                id="employee-lastName"
                name="lastName"
                data-cy="lastName"
                type="text"
                // onChange={e => setLastName(e.target.value)} // Added by Shafqat
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {/* {isNew ? (
                <ValidatedField
                  label={translate('hrmsApp.employee.qlid') + '*'}
                  id="employee-qlid"
                  name="qlid"
                  data-cy="qlid"
                  type="text"
                  value={qlid}
                  // readOnly // Make it read-only if needed
                  // onChange={e => setQlid(e.target.value)} // Allow manual input as well
                  // validate={{
                  //   required: { value: true, message: translate('entity.validation.required') },
                  // }}
                />
              ) : null} */}
              <ValidatedField
                label={translate('hrmsApp.employee.dob') + '*'}
                id="employee-dob"
                name="dob"
                data-cy="dob"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {!isNew ? (
                <ValidatedField
                  label={translate('hrmsApp.employee.joiningDate')}
                  id="employee-joiningDate"
                  name="joiningDate"
                  data-cy="joiningDate"
                  type="date"
                />
              ) : null}
              {!isNew ? (
              <ValidatedField
                id="employee-user"
                name="user"
                data-cy="user"
                readOnly
                label={translate('hrmsApp.employee.user')}
                type="select"
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              ) : null}
              {!isNew ? (
              <ValidatedField
                id="employee-reportingLine"
                name="reportingLine"
                data-cy="reportingLine"
                label={translate('hrmsApp.employee.reportingLine')}
                type="select"
              >
                <option value="" key="0" />
                {employees? employees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.qlid} : {' (' + otherEntity.firstName + ' ' + otherEntity.lastName + ')'}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              ) : null}
              {isNew
                ? null /* (
                <ValidatedField
                  id="employee-employeeStatus"
                  name="employeeStatus"
                  data-cy="employeeStatus"
                  label={translate('hrmsApp.employee.employeeStatus')}
                  type="select"
                  readonly
             //     required
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'EMS' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              )*/
                : null}
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/employee" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
      <Row id="hr-code-message" className="justify-content-center text-white m-0 py-1"></Row>
    </div>
  );
};

export default EmployeeUpdate;
