import dayjs from 'dayjs';
import { IHrCode } from 'app/shared/model/hr-code.model';
import { IUser } from 'app/shared/model/user.model';

export interface IEmployee {
  id?: number;
  qlid?: string;
  firstName?: string;
  lastName?: string | null;
  dob?: dayjs.Dayjs;
  joiningDate?: dayjs.Dayjs | null;
  employeeType?: IHrCode;
  user?: IUser | null;
  reportingLine?: IEmployee | null;
  financeLine?: IEmployee | null;
  adhocLine?: IEmployee | null;
  employeeStatus?: IHrCode;
}

export const defaultValue: Readonly<IEmployee> = {};
