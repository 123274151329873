import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TaskAssignment from './task-assignment';
import TaskAssignmentDetail from './task-assignment-detail';
import TaskAssignmentUpdate from './task-assignment-update';
import TaskAssignmentDeleteDialog from './task-assignment-delete-dialog';

const TaskAssignmentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TaskAssignment />} />
    <Route path="new" element={<TaskAssignmentUpdate />} />
    <Route path=":id">
      <Route index element={<TaskAssignmentDetail />} />
      <Route path="edit" element={<TaskAssignmentUpdate />} />
      <Route path="delete" element={<TaskAssignmentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TaskAssignmentRoutes;
