import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './approval-workflow.reducer';

export const ApprovalWorkflowDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const approvalWorkflowEntity = useAppSelector(state => state.approvalWorkflow.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="approvalWorkflowDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.approvalWorkflow.detail.title">ApprovalWorkflow</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{approvalWorkflowEntity.id}</dd>
          <dt>
            <span id="roleName">
              <Translate contentKey="hrmsApp.approvalWorkflow.roleName">Role</Translate>
            </span>
          </dt>
          <dd>{approvalWorkflowEntity.roleName}</dd>
          <dt>
            <span id="step">
              <Translate contentKey="hrmsApp.approvalWorkflow.step">Step</Translate>
            </span>
          </dt>
          <dd>{approvalWorkflowEntity.step}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalWorkflow.workflow">Workflow</Translate>
          </dt>
          <dd>{approvalWorkflowEntity.workflow ? approvalWorkflowEntity.workflow.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalWorkflow.action">Action</Translate>
          </dt>
          <dd>{approvalWorkflowEntity.action ? approvalWorkflowEntity.action.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalWorkflow.status">Status</Translate>
          </dt>
          <dd>{approvalWorkflowEntity.status ? approvalWorkflowEntity.status.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/approval-workflow" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/approval-workflow/${approvalWorkflowEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ApprovalWorkflowDetail;
