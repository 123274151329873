import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LeavesRecord from './leaves-record';
import LeavesRecordDetail from './leaves-record-detail';
import LeavesRecordUpdate from './leaves-record-update';
import LeavesRecordDeleteDialog from './leaves-record-delete-dialog';

const LeavesRecordRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LeavesRecord />} />
    <Route path="new" element={<LeavesRecordUpdate />} />
    <Route path=":id">
      <Route index element={<LeavesRecordDetail />} />
      <Route path="edit" element={<LeavesRecordUpdate />} />
      <Route path="delete" element={<LeavesRecordDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LeavesRecordRoutes;
