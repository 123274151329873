import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './emp-dependent.reducer';

export const EmpDependentDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const empDependentEntity = useAppSelector(state => state.empDependent.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="empDependentDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.empDependent.detail.title">EmpDependent</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{empDependentEntity.id}</dd>
          <dt>
            <span id="fullName">
              <Translate contentKey="hrmsApp.empDependent.fullName">Full Name</Translate>
            </span>
          </dt>
          <dd>{empDependentEntity.fullName}</dd>
          <dt>
            <span id="relationInfo">
              <Translate contentKey="hrmsApp.empDependent.relationInfo">Relation Info</Translate>
            </span>
          </dt>
          <dd>{empDependentEntity.relationInfo}</dd>
          <dt>
            <span id="cnic">
              <Translate contentKey="hrmsApp.empDependent.cnic">Cnic</Translate>
            </span>
          </dt>
          <dd>{empDependentEntity.cnic}</dd>
          <dt>
            <span id="dob">
              <Translate contentKey="hrmsApp.empDependent.dob">Dob</Translate>
            </span>
          </dt>
          <dd>
            {empDependentEntity.dob ? <TextFormat value={empDependentEntity.dob} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="occupation">
              <Translate contentKey="hrmsApp.empDependent.occupation">Occupation</Translate>
            </span>
          </dt>
          <dd>{empDependentEntity.occupation}</dd>
          <dt>
            <span id="contactNo">
              <Translate contentKey="hrmsApp.empDependent.contactNo">Contact No</Translate>
            </span>
          </dt>
          <dd>{empDependentEntity.contactNo}</dd>
          <dt>
            <span id="curAddress">
              <Translate contentKey="hrmsApp.empDependent.curAddress">Cur Address</Translate>
            </span>
          </dt>
          <dd>{empDependentEntity.curAddress}</dd>
          <dt>
            <Translate contentKey="hrmsApp.empDependent.employee">Employee</Translate>
          </dt>
          <dd>{empDependentEntity.employee ? empDependentEntity.employee.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/emp-dependent" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/emp-dependent/${empDependentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmpDependentDetail;
