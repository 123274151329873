import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AllowanceItem from './allowance-item';
import AllowanceItemDetail from './allowance-item-detail';
import AllowanceItemUpdate from './allowance-item-update';
import AllowanceItemDeleteDialog from './allowance-item-delete-dialog';

const AllowanceItemRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AllowanceItem />} />
    <Route path="new" element={<AllowanceItemUpdate />} />
    <Route path=":id">
      <Route index element={<AllowanceItemDetail />} />
      <Route path="edit" element={<AllowanceItemUpdate />} />
      <Route path="delete" element={<AllowanceItemDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AllowanceItemRoutes;
