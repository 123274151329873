import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, ValidatedField, ValidatedForm, getSortState, translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntities, updateEntity } from './country-holiday.reducer';

export const CountryHoliday = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const countryHolidayList = useAppSelector(state => state.countryHoliday.entities);
  const loading = useAppSelector(state => state.countryHoliday.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
    if (hrCodes===null || hrCodes===undefined) {
      dispatch(getHrCodes({}));
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };



  /* eslint no-console: off */
  // Start Update
  // Multiple Selection
  // const [selectedBUs, setSelectedBUs] = useState<number[]>([]);
  // const toggleSelectionMulti = (item: number) => {
  //   if (selectedBUs.includes(item)) {
  //       setSelectedBUs(selectedBUs.filter(i => i !== item));
  //   } else {
  //       setSelectedBUs([...selectedBUs, item]);
  //   }
  // };
  const [isNew, setIsNew] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>();
  const [selectedEntity, setSelectedEntity] = useState(countryHolidayList.find(e=>e.id===-1));
  
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const updating = useAppSelector(state => state.countryHoliday.updating);
  const updateSuccess = useAppSelector(state => state.countryHoliday.updateSuccess);

  const handleSelectionChange = (event) => {
    console.log('------------- selectedId: ', event.target.value);
    handleClose();
    setSelectedOption(Number.isNaN(parseInt(event.target.value, 10)) ? null : parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    setIsNew(false);
    setSelectedEntity(null);
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const handleNew = () => {
    setIsNew(true);
  };

  const handleEdit = (selectedId) => {
    setSelectedEntity(countryHolidayList.find(e=>e.id===selectedId));
    console.log('-------- SelectedId: ', selectedId, ' --- ', isNew);
  };

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...selectedEntity,
      ...values,
      country: isNew? hrCodes.find(e=>e.id===selectedOption) : hrCodes.find(it => it.id.toString() === values.country?.toString()),
      status: isNew? hrCodes.find(e=>e.codeType==='WKS' && e.codeName==='Pending') : hrCodes.find(it => it.id.toString() === values.status?.toString()),
    };
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
    handleClose();
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...selectedEntity,
          country: selectedEntity?.country?.id,
          status: selectedEntity?.status?.id,
        };
  // End Update

  return (
    <div>
      <h5 id="country-heading" className="text-center text-primary fw-bold p-1">
        Country
      </h5>
      <div className="container mb-5">
        {hrCodes && hrCodes.filter(e=>e.codeType === 'COU' && e.id%100>0).length > 0 ? (
            <ValidatedField name="mainSelection" type="select" value={selectedOption} onChange={handleSelectionChange}>
              <option value="" key="0" />
              {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'COU' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
            </ValidatedField>
          ) : !loading && ( <div className="alert alert-warning"> No Data found </div> )
        }
        {selectedOption? (
          <>
            <h5 id="country-holiday-heading" data-cy="CountryHolidayHeading" className="text-center text-primary fw-bold p-1 mt-5">
              {hrCodes.find(e => e.id === selectedOption)?.codeDescription ?? hrCodes.find(e => e.id === selectedOption)?.codeName} Holidays
            </h5>
            <div className="table-responsive">
              {countryHolidayList && countryHolidayList.filter(e=>e.country.id===selectedOption).length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="hand" onClick={sort('dated')}>
                        <Translate contentKey="hrmsApp.countryHoliday.dated">Dated</Translate>{' '}
                        <FontAwesomeIcon icon={getSortIconByFieldName('dated')} />
                      </th>
                      <th className="hand" onClick={sort('remarks')}>
                        <Translate contentKey="hrmsApp.countryHoliday.remarks">Remarks</Translate>{' '}
                        <FontAwesomeIcon icon={getSortIconByFieldName('remarks')} />
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {countryHolidayList.filter(e=>e.country.id===selectedOption).map((countryHoliday, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        <td>
                          {countryHoliday.dated ? <TextFormat type="date" value={countryHoliday.dated} format={APP_LOCAL_DATE_FORMAT} /> : null}
                        </td>
                        <td>{countryHoliday.remarks}</td>
                        <td className="text-end">
                          <div className="btn-group flex-btn-group-container">
                            <Button id="edit-entity" onClick={() => handleEdit(countryHoliday.id)} color="primary" size="sm" data-cy="entityEditButton" disabled={isNew||selectedEntity!==null}>
                              <FontAwesomeIcon icon="pencil-alt" />{' '}
                            </Button>
                            <Button onClick={() => (window.location.href = `/country-holiday/${countryHoliday.id}/delete`)} color="danger" size="sm" data-cy="entityDeleteButton">
                                <FontAwesomeIcon icon="trash" />{' '}
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning">
                    <Translate contentKey="hrmsApp.countryHoliday.home.notFound">No Country Holidays found</Translate>
                  </div>
                )
              )}
            </div>
            <div className="d-flex justify-content-end mb-3">
              <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              </Button>
              <Button id="add-entity" className="me-2" color="primary" onClick={handleNew} disabled={loading || (isNew || selectedEntity!==null)}>
                <FontAwesomeIcon icon="plus" spin={loading} />{' '}
              </Button>
            </div>
            {(isNew || selectedEntity!==null) ? (
                  <Row className="justify-content-center text-primary fw-bold bg-light py-2 m-0">
                    <Col md="8">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                          <ValidatedField
                            label={translate('hrmsApp.countryHoliday.dated')}
                            id="country-holiday-dated"
                            name="dated"
                            data-cy="dated"
                            type="date"
                            validate={{
                              required: { value: true, message: translate('entity.validation.required') },
                            }}
                          />
                          <ValidatedField
                            label={translate('hrmsApp.countryHoliday.remarks')}
                            id="country-holiday-remarks"
                            name="remarks"
                            data-cy="remarks"
                            type="text"
                          />
                          <Button color="info" size="sm" id="cancel-save" data-cy="entityCreateCancelButton" onClick={handleClose}><FontAwesomeIcon icon="arrow-left" />&nbsp;</Button>
                          &nbsp;
                          <Button color="primary"  size="sm" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}><FontAwesomeIcon icon="save" />&nbsp;</Button>
                        </ValidatedForm>
                      )}
                    </Col>
                  </Row>
                ) : null
              }
          </>
          ) : ''
        }
      </div>
    </div>
  );
};

export default CountryHoliday;
