import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FcmToken from './fcm-token';
import FcmTokenDetail from './fcm-token-detail';
import FcmTokenUpdate from './fcm-token-update';
import FcmTokenDeleteDialog from './fcm-token-delete-dialog';

const FcmTokenRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FcmToken />} />
    <Route path="new" element={<FcmTokenUpdate />} />
    <Route path=":id">
      <Route index element={<FcmTokenDetail />} />
      <Route path="edit" element={<FcmTokenUpdate />} />
      <Route path="delete" element={<FcmTokenDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FcmTokenRoutes;
