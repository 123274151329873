import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IInternalTask {
  id?: number;
  minHour?: number;
  maxHour?: number;
  intTask?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<IInternalTask> = {};
