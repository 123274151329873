import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities as getBusinessClients } from 'app/entities/business-client/business-client.reducer';
import { getEntities as getBusinessProjects } from 'app/entities/business-project/business-project.reducer';
import { getEntities as getInternalTasks } from 'app/entities/internal-task/internal-task.reducer';
import { getEntities as getProjectTasks } from 'app/entities/project-task/project-task.reducer';
import { getEntities as getAssignments } from 'app/entities/task-assignment/task-assignment.reducer';
import { getEntities } from './time-sheet.reducer';

import { addDays, format, startOfWeek } from 'date-fns';

interface Task {
  id: number;
  name: string;
  parent: string;
  project: string;
}
type Matrix = Task[][];
const initialTasks: Task[][] = [];
const taskList: Task[][] = [];

export const TimeSheet = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const getWeekDates = (weekStartDate: Date): string[] => {
    const weekDates: string[] = [];
    let currentDate = startOfWeek(weekStartDate, { weekStartsOn: 1 }); // Week starts on Monday
    // weekDates.push('Task');
    for (let i = 0; i < 7; i++) {
      weekDates.push(format(currentDate, 'yyyy-MM-dd'));
      // weekDates.push(currentDate);
      currentDate = addDays(currentDate, 1);
    }
    return weekDates;
  };

  const weekStartDate = new Date(2024, 1, 1); // January 1, 2024 (Monday)
  const weekDates = getWeekDates(weekStartDate);
  // weekDates.forEach(d => console.log(d.toDateString()));
  // weekDates.forEach(d => console.log(format(d, 'EEE MMM-dd')));
  weekDates.forEach(d => console.log(d));

  const [taskMatrix, setTaskMatrix] = useState<Matrix>(initialTasks);

  // const generateWeekStartDates = (year: number): Date[] => {
  //   const firstDayOfYear = new Date(year, 0, 1);
  //   const firstWeekStart = startOfWeek(firstDayOfYear, { weekStartsOn: 1 });  
  //   const weekStartDates: Date[] = [];
  //   let currentWeekStart = firstWeekStart;
  //   while (getYear(currentWeekStart) <= year) {
  //     if (getYear(currentWeekStart) === year) {
  //       weekStartDates.push(currentWeekStart);
  //     }
  //     currentWeekStart = addWeeks(currentWeekStart, 1);
  //   }
  //   return weekStartDates;
  // };
  
  // //Example usage:
  // const year = 2023;
  // const weekStartDates = generateWeekStartDates(year);
  // weekStartDates.forEach(date => console.log(date.toDateString()));


  // const generateRelevantWeekStartDates = (date: Date): Date[] => {
  //   const year = getYear(date);
  //   const month = getMonth(date);
  //   const startDate = startOfMonth(date);
  //   let firstWeekStart = startOfWeek(startDate, { weekStartsOn: 1 });
  //   const weekStartDates: Date[] = [];
  
  //   // Check if the date falls in the first week of the month
  //   const dateWeekStart = startOfWeek(date, { weekStartsOn: 1 });
  //   const isFirstWeek = differenceInCalendarWeeks(date, startDate, { weekStartsOn: 1 }) === 0;
  //   if (isFirstWeek) {
  //     // If in the first week, get weeks of the previous month and the current week
  //     const prevMonthDate = subWeeks(startDate, 1);
  //     const prevMonth = getMonth(prevMonthDate);
  //     while (getMonth(firstWeekStart) === prevMonth || getMonth(firstWeekStart) === month) {
  //       if (getMonth(firstWeekStart) === prevMonth) {
  //         weekStartDates.push(firstWeekStart);
  //       }
  //       firstWeekStart = addWeeks(firstWeekStart, 1);
  //     }
  //     // Ensure the current week is included
  //     weekStartDates.push(dateWeekStart);
  //   } else {
  //     // If not in the first week, get all weeks of the current month
  //     let currentWeekStart = firstWeekStart;
  //     while (getMonth(currentWeekStart) === month) {
  //       weekStartDates.push(currentWeekStart);
  //       currentWeekStart = addWeeks(currentWeekStart, 1);
  //     }
  //   }
  //   return weekStartDates;
  // };
      
  // // Example usage:
  // const inputDate = new Date(2024, 2, 4); // January 4, 2024
  // const weekStartDates = generateRelevantWeekStartDates (inputDate);
  // weekStartDates.forEach(date1 => console.log(date1.toDateString()));
  
  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const businessClients = useAppSelector(state => state.businessClient.entities);
  const businessProjects = useAppSelector(state => state.businessProject.entities);
  const projectTasks = useAppSelector(state=> state.projectTask.entities);
  const taskAssignments = useAppSelector(state=>state.taskAssignment.entities);
  const timeSheetList = useAppSelector(state => state.timeSheet.entities);
  const internalTasks = useAppSelector(state => state.internalTask.entities);
  const empProfile = useAppSelector(state=> state.empProfile.entity);
  const employees = useAppSelector(state=>state.employee.entities);
  const hrCodes = useAppSelector(state=>state.hrCode.entities);
  const loading = useAppSelector(state => state.timeSheet.loading);

  const timeSheetHeader = ['ProjectTask','TaskId','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const [timeSheetTasks, setTimeSheetTasks] = useState<Task[]>([]);

  // useEffect(() => {
  //   console.log('------ AssignmentList: ', timeSheetList?.length, ' - ', assignmentList?.length, ' Emp: ', employees.length, ' Profile: ', empProfile);
  //   internalTaskList.map((task, i) => { t Task = new task(iid=   taskList.push(i.id, i.intTask.codeName });
  //   // setTasksMatrix(internalTaskList);
  //   console.log('---------- Internal: ', taskList);
  // }, [internalTaskList]);

  const transformTasksToMatrix = (tasks: Task[], weekDates1: string[]): Matrix => {
    // Create a matrix with the same number of rows as tasks and columns as weekDates
    const matrix: Matrix = tasks.map(task => {
      // For simplicity, assume each task is duplicated for each day of the week
      // Adjust this logic based on your actual task distribution logic
      return weekDates1.map(() => ({ ...task }));
    });
    return matrix;
  };

  useEffect(() => {
    // Merge the id and name fields from both lists
    const mergedEntries: Task[] = [
      ...taskAssignments.map(assignment => ({ 
        id: assignment.id, 
        name: projectTasks.find(e=>e.id===assignment.projectTask.id)?.taskName,
        parent: projectTasks.find(e=>e.id===(assignment.projectTask.id)?.parentTask?.id)?.taskName,
        project: businessProjects.find(e=>e.id===(projectTasks.find(t=>t.id===assignment.projectTask.id)?.businessProject.id))?.projectName })),
      ...internalTasks.map(task => ({ id: task.id, name: hrCodes.find(e=>e.id===task.intTask.id)?.codeName, parent: null, project: 'Admin' })),
    ];

    setTimeSheetTasks(mergedEntries);
  }, [internalTasks, taskAssignments]);

  useEffect(() => {
    console.log('----- TimeSheet Tasks: ', timeSheetTasks);
    const matrix = transformTasksToMatrix(timeSheetTasks, weekDates);
    setTaskMatrix(matrix);
  }, [timeSheetTasks]);

  useEffect(() => {
    console.log('----- Task Matrix: ', taskMatrix);
  }, [taskMatrix]);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  /* eslint no-console: off */
  useEffect(() => {
    if (businessClients.length===0) {
      dispatch(getBusinessClients({}));
    }
    if (businessProjects.length===0) {
      dispatch(getBusinessProjects({}));
    }
    if (projectTasks.length===0) {
      dispatch(getProjectTasks({query:'statusId=123'}));
    }
    if (taskAssignments.length===0) {
      dispatch(getAssignments({}));
    } 
    if (internalTasks.length===0) {
      dispatch(getInternalTasks({}));
    }
    console.log('------ Load: ', timeSheetList?.length, ' - ', taskAssignments?.length, ' Emp: ', employees.length, ' Profile: ', empProfile);

    // console.log(weekDates); // Output the array of dates for the week
  }, []);

  useEffect(() => {
    const ownAssignment = taskAssignments? taskAssignments.filter(e=>e.employee.id===empProfile.id) : null;
    console.log('------ OwnAssignment: ', timeSheetList?.length, ' - ', ownAssignment, ' Emp: ', employees.length, ' Profile: ', empProfile);
  }, [empProfile]);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const handleCellClick = (rowIndex: number, colIndex: number, task: Task) => {
    alert(`Row: ${rowIndex}, Column: ${colIndex} Task: ${task.id} ${task.name}`);
  };

  const getProjectTask = (id: number) => {
    return projectTasks.find(e=>e.id===id);
  }

  return (
    <div>
      <h5 id="time-sheet-heading" data-cy="TimeSheetHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.timeSheet.home.title">Time Sheets</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          </Button>
          <Link to="/time-sheet/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
          </Link>
        </div>
      </h5>
      <div className="table-hover">
      {internalTasks.length>0 && taskAssignments.length>0? (
          <Table hover>
          <thead>
            <tr>
              <th/>
              {weekDates.map((day, index) => (
                <th key={index}>{format(day, 'MMM-dd (EEE)')}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          {taskMatrix.map((row, rowIndex) => (
            // let cTask = timeSheetTasks[rowIndex];

            <tr key={rowIndex}>
              <td className={timeSheetTasks[rowIndex]?.project==='Admin'? 'bg-light' : ''}> 
                <span className='text-primary fw-bold me-2'>{timeSheetTasks[rowIndex]?.project}</span>
                  ({timeSheetTasks[rowIndex]?.parent? `${timeSheetTasks[rowIndex].parent} -- `:''} {timeSheetTasks[rowIndex]?.name}) 
              </td>
              {row.map((task, colIndex) => (
                <td key={colIndex} onClick={() => handleCellClick(rowIndex, colIndex, task)} className={colIndex>=0? 'border border-gray' :'fw-bold text-primary'}>{colIndex>=0? '' : task.name}</td>
              ))}
            </tr>
          ))}
          </tbody>
        </Table>
        )
        : ''
      }
      </div>



{/* 
      <div className="table-responsive">
        {timeSheetList && timeSheetList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.timeSheet.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('dated')}>
                  <Translate contentKey="hrmsApp.timeSheet.dated">Dated</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('dated')} />
                </th>
                <th className="hand" onClick={sort('hoursSpent')}>
                  <Translate contentKey="hrmsApp.timeSheet.hoursSpent">Hours Spent</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hoursSpent')} />
                </th>
                <th className="hand" onClick={sort('activites')}>
                  <Translate contentKey="hrmsApp.timeSheet.activites">Activites</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('activites')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.timeSheet.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.timeSheet.taskType">Task Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.timeSheet.assignment">Assignment</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.timeSheet.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {timeSheetList.map((timeSheet, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/time-sheet/${timeSheet.id}`} color="link" size="sm">
                      {timeSheet.id}
                    </Button>
                  </td>
                  <td>{timeSheet.dated ? <TextFormat type="date" value={timeSheet.dated} format={APP_LOCAL_DATE_FORMAT} /> : null}</td>
                  <td>{timeSheet.hoursSpent}</td>
                  <td>{timeSheet.activites}</td>
                  <td>{timeSheet.employee ? <Link to={`/employee/${timeSheet.employee.id}`}>{timeSheet.employee.id}</Link> : ''}</td>
                  <td>{timeSheet.taskType ? <Link to={`/hr-code/${timeSheet.taskType.id}`}>{timeSheet.taskType.id}</Link> : ''}</td>
                  <td>
                    {timeSheet.assignment ? <Link to={`/task-assignment/${timeSheet.assignment.id}`}>{timeSheet.assignment.id}</Link> : ''}
                  </td>
                  <td>{timeSheet.status ? <Link to={`/hr-code/${timeSheet.status.id}`}>{timeSheet.status.id}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/time-sheet/${timeSheet.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`/time-sheet/${timeSheet.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => (window.location.href = `/time-sheet/${timeSheet.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.timeSheet.home.notFound">No Time Sheets found</Translate>
            </div>
          )
        )}
      </div> */}
    </div>
  );
};

export default TimeSheet;
