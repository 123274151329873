import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCuWB8ksHQ5f8USmWVa2HytzMHbLT9CqSI",
    authDomain: "dmt-hrms.firebaseapp.com",
    projectId: "dmt-hrms",
    storageBucket: "dmt-hrms.appspot.com",
    messagingSenderId: "38858781517",
    appId: "1:38858781517:web:3033cffaddd345d9733d04"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export default messaging;
