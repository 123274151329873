import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntities, getEntity, reset, updateEntity } from './contract-template.reducer';

export const ContractTemplateUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const parsedId = parseInt(id, 10); // Parse the id as a number if it's a string
  
  const [stateId, setStateId] = useState<number | undefined>(parsedId);
  const [isNew, setIsNew] = useState(id === undefined);
  
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const contractTemplateEntity = useAppSelector(state => state.contractTemplate.entity);
  const contractTemplateList = useAppSelector(state => state.contractTemplate.entities);
  const loading = useAppSelector(state => state.contractTemplate.loading);
  const updating = useAppSelector(state => state.contractTemplate.updating);
  const updateSuccess = useAppSelector(state => state.contractTemplate.updateSuccess);

  const handleClose = () => {
    navigate('/contract-template' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(getEntities({}));
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getHrCodes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    console.log(' ------ Contract Status: ', contractTemplateEntity?.templateStatus?.id);

    const entity = {
      ...contractTemplateEntity,
      ...values,
      templateCode: hrCodes.find(it => it.id.toString() === values.templateCode.toString()),
      employmentType: hrCodes.find(it => it.id.toString() === values.employmentType.toString()),
      payrollOffice: hrCodes.find(it => it.id.toString() === values.payrollOffice.toString()),
      currency: hrCodes.find(it => it.id.toString() === values.currency.toString()),
      contractStatus: hrCodes.find(it => (it.codeType==='CTS' && it.codeName==='Pending')), //')id.toString() === values.contractStatus.toString()),
      // templateStatus: 0,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...contractTemplateEntity,
          templateCode: contractTemplateEntity?.templateCode?.id,
          employmentType: contractTemplateEntity?.employmentType?.id,
          payrollOffice: contractTemplateEntity?.payrollOffice?.id,
          currency: contractTemplateEntity?.currency?.id,
          templateStatus: contractTemplateEntity?.templateStatus?.id,
      };

  /* eslint no-console: off */
  const [selectedTemplateCode, setSelectedTemplateCode] = useState('');
  const [selectedEmpType, setSelectedEmpType] = useState('');

  const handleTemplateCodeChange = (event) => {
    setSelectedTemplateCode(event.target.value);
    if (event.target.value && selectedEmpType) {
      const cont = contractTemplateList.filter(t=> t.templateCode.id.toString()===event.target.value && t.employmentType.id.toString()===selectedEmpType.toString());
      if (cont.length > 0) {
        alert("You have already added this Template");
        navigate('/contract-template/'+cont[0].id+'/edit');
      }
    }
  };

  const handleEmpTypeChange = (event) => {
    setSelectedEmpType(event.target.value);
    if (event.target.value && selectedTemplateCode) {
      const cont = contractTemplateList.filter(t=> t.templateCode.id.toString()===selectedTemplateCode.toString() && t.employmentType.id.toString()===event.target.value);
      if (cont.length > 0) {
        alert("You have already added this Template");
        navigate('/contract-template/'+cont[0].id+'/edit');
      }
    }
  };
  
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 id="hrmsApp.contractTemplate.home.createOrEditLabel" data-cy="ContractTemplateCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.contractTemplate.home.createOrEditLabel">Create or edit a ContractTemplate</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-3 text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {/* {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="contract-template-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null} */}
              <ValidatedField
                id="contract-template-templateCode"
                name="templateCode"
                data-cy="templateCode"
                label={translate('hrmsApp.contractTemplate.templateCode')+"*"}
                type="select"
                disabled= {!isNew}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                onChange={handleTemplateCodeChange}
                // value={selectedTemplateCode}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'CTT' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="contract-template-employmentType"
                name="employmentType"
                data-cy="employmentType"
                label={translate('hrmsApp.contractTemplate.employmentType')+"*"}
                type="select"
                disabled={!isNew}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                onChange={handleEmpTypeChange}
                // value={selectedEmpType}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'EMT' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="contract-template-payrollOffice"
                name="payrollOffice"
                data-cy="payrollOffice"
                label={translate('hrmsApp.contractTemplate.payrollOffice')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'ORG' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="contract-template-currency"
                name="currency"
                data-cy="currency"
                label={translate('hrmsApp.contractTemplate.currency')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'CUR' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.contractTemplate.hourlyBased')}
                id="contract-template-hourlyBased"
                name="hourlyBased"
                data-cy="hourlyBased"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.contractTemplate.allowance')}
                id="contract-template-allowance"
                name="allowance"
                data-cy="allowance"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.contractTemplate.aip')}
                id="contract-template-aip"
                name="aip"
                data-cy="aip"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.contractTemplate.gf')}
                id="contract-template-gf"
                name="gf"
                data-cy="gf"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.contractTemplate.pf')}
                id="contract-template-pf"
                name="pf"
                data-cy="pf"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.contractTemplate.onsiteAllowance')}
                id="contract-template-onsiteAllowance"
                name="onsiteAllowance"
                data-cy="onsiteAllowance"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.contractTemplate.performanceBonus')}
                id="contract-template-performanceBonus"
                name="performanceBonus"
                data-cy="performanceBonus"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('hrmsApp.contractTemplate.medical')}
                id="contract-template-medical"
                name="medical"
                data-cy="medical"
                check
                type="checkbox"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/contract-template" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ContractTemplateUpdate;
