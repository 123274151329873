import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FcmTemplate from './fcm-template';
import FcmTemplateDetail from './fcm-template-detail';
import FcmTemplateUpdate from './fcm-template-update';
import FcmTemplateDeleteDialog from './fcm-template-delete-dialog';

const FcmTemplateRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FcmTemplate />} />
    <Route path="new" element={<FcmTemplateUpdate />} />
    <Route path=":id">
      <Route index element={<FcmTemplateDetail />} />
      <Route path="edit" element={<FcmTemplateUpdate />} />
      <Route path="delete" element={<FcmTemplateDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FcmTemplateRoutes;
