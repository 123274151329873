import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';
import { IContractTemplate } from 'app/shared/model/contract-template.model';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IContract {
  id?: number;
  startedOn?: dayjs.Dayjs;
  endedOn?: dayjs.Dayjs | null;
  probationCompletedOn?: dayjs.Dayjs | null;
  hourlyRate?: number | null;
  baseSalary?: number | null;
  onsiteAllowance?: number | null;
  flightAllowance?: number | null;
  aip?: number | null;
  performanceBonus?: number | null;
  revisionNo?: number | null;
  employee?: IEmployee;
  contractTemplate?: IContractTemplate;
  payrollOffice?: IHrCode;
  currency?: IHrCode;
  designation?: IHrCode;
  jobRole?: IHrCode;
  jobGrade?: IHrCode | null;
  department?: IHrCode;
  location?: IHrCode | null;
  allowancePlan?: IHrCode | null;
  aipPlan?: IHrCode | null;
  bonusPlan?: IHrCode | null;
  gfPlan?: IHrCode | null;
  pfPlan?: IHrCode | null;
  medicalPlan?: IHrCode | null;
  leavesPlan?: IHrCode | null;
  contractStatus?: IHrCode;
}

export const defaultValue: Readonly<IContract> = {};
