import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { createEntity, getEntity, reset, updateEntity } from './emp-experience.reducer';

export const EmpExperienceUpdate = () => {
  const account = useAppSelector(state => state.authentication.account);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  // const employees = useAppSelector(state => state.employee.entities);
  const empExperienceEntity = useAppSelector(state => state.empExperience.entity);
  const loading = useAppSelector(state => state.empExperience.loading);
  const updating = useAppSelector(state => state.empExperience.updating);
  const updateSuccess = useAppSelector(state => state.empExperience.updateSuccess);

  const handleClose = () => {
    navigate('/emp-experience');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    // dispatch(getEmployees({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...empExperienceEntity,
      ...values,
      // employee: account?.employeeRef?.toString(), //employees.find(it => it.id.toString() === values.employee.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...empExperienceEntity,
          employee: empExperienceEntity?.employee?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 id="hrmsApp.empExperience.home.createOrEditLabel" data-cy="EmpExperienceCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.empExperience.home.createOrEditLabel">Create or edit a EmpExperience</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-3 text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {/* {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="emp-experience-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null} */}
              <ValidatedField
                label={translate('hrmsApp.empExperience.organization')}
                id="emp-experience-organization"
                name="organization"
                data-cy="organization"
                // className="row d-flex"
                // labelClass="col-sm-5"
                // inputClass="col-sm-7 custom-input flex-grow-1 form-control-sm"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empExperience.fromDate')}
                id="emp-experience-fromDate"
                name="fromDate"
                data-cy="fromDate"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empExperience.toDate')}
                id="emp-experience-toDate"
                name="toDate"
                data-cy="toDate"
                type="date"
                // validate={{
                //   required: { value: true, message: translate('entity.validation.required') },
                // }}
              />
              <ValidatedField
                label={translate('hrmsApp.empExperience.lastRole')}
                id="emp-experience-lastRole"
                name="lastRole"
                data-cy="lastRole"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empExperience.responsibility')}
                id="emp-experience-responsibility"
                name="responsibility"
                data-cy="responsibility"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxbytes', { max: 100 })},
                }}
                type="textarea"
              />
              <ValidatedField
                label={translate('hrmsApp.empExperience.achievement')}
                id="emp-experience-achievement"
                name="achievement"
                data-cy="achievement"
                type="textarea"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxbytes', { max: 100 })},
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empExperience.stillWorking')}
                id="emp-experience-stillWorking"
                name="stillWorking"
                data-cy="stillWorking"
                check
                type="checkbox"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/emp-experience" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EmpExperienceUpdate;
